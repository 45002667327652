@import '~bootstrap/scss/mixins';

.main-slider {
  padding-top: 70px;
  margin-bottom: 48px;
  user-select: none;

  @include media-breakpoint-down(md) {
    padding-top: 20px;
    margin-bottom: 20px;
  }

  .item-slide {
    .img-box {
      text-align: center;
      margin-bottom: 60px;
      position: relative;

      &:before {
        content: '';
        width: 100%;
        height: calc(100% + 60px);
        position: absolute;
        top: 0;
        left: 0;
        background: url(../images/bg/bg-products.webp) center center no-repeat;
        z-index: -1;
        background-size: 95%;
        opacity: 0.1;
      }
    }

    .bottom-info {
      display: flex;
      justify-content: space-between;
      position: relative;
      z-index: 0;
      padding-left: 15px;

      @include media-breakpoint-down(md) {
        display: block;
        text-align: center;
      }

      &:before {
        content: '';
        width: 37px;
        height: 100px;
        position: absolute;
        top: -60px;
        left: 0;
        z-index: -1;
        background: url(../images/icons/bg-title.svg) no-repeat left top;
      }

      .title {
        font-size: 40px;
        font-weight: 600;
        line-height: 46px;
        text-transform: uppercase;
        max-width: 60%;

        @include media-breakpoint-down(md) {
          margin-bottom: 15px;
          font-size: 30px;
          line-height: 36px;
          max-width: 100%;
        }
      }

      a.green-btn {
        height: fit-content;
        position: relative;
        bottom: 50px;

        @media (max-width: 1099px) {
          bottom: -5px;
        }
      }
    }
  }

  .slider-nav {
    max-width: 1220px;
    width: 100%;
    position: relative;
    z-index: 0;
    margin: 40px auto 0;
    height: 56px;

    .bg-arrow {
      position: absolute;
      top: -100%;
      z-index: -1;
      width: 142px;
      height: 76px;

      @include media-breakpoint-down(lg) {
        width: 100px;
      }

      @include media-breakpoint-down(sm) {
        display: none;
      }

      &.left {
        left: 0;
        background: url(../images/icons/slider-arrow-left.svg) no-repeat center;
        background-size: contain;
      }

      &.right {
        right: 0;
        background: url(../images/icons/slider-arrow-right.svg) no-repeat center;
        background-size: contain;
      }
    }

    ul {
      display: flex;
      justify-content: center;
      align-items: center;

      .prev {
        border: none;
        margin: 0;
        display: flex;
        width: 44px;
        height: 44px;
        background: url(../images/icons/slider-arrow-left-elips.svg) no-repeat center;

        &:hover {
          opacity: 0.7;
        }
      }

      .next {
        border: none;
        margin: 0;
        display: flex;
        width: 44px;
        height: 44px;
        background: url(../images/icons/slider-arrow-right-elips.svg) no-repeat center;

        &:hover {
          opacity: 0.7;
        }
      }

      .count {
        margin: 0 24px;
        font-size: 28px;
        line-height: 56px;
        text-transform: uppercase;
        color: #F5F5F7;
      }
    }
  }
}

.slider-navigation {
  padding-left: inherit;
  padding-right: inherit;
  left: 0;
  right: 0;

  top: calc(50% - 22px);

  .prev {
    border: none;
    margin: 0;
    display: inline-block;
    width: 44px;
    height: 44px;
    background: url(../images/icons/slider-arrow-left-elips.svg) no-repeat center;
    opacity: 0.5;
    cursor: pointer;
    z-index: 1;

    &:hover {
      opacity: 1;
    }
  }

  .next {
    border: none;
    margin: 0;
    display: inline-block;
    width: 44px;
    height: 44px;
    background: url(../images/icons/slider-arrow-right-elips.svg) no-repeat center;
    opacity: 0.5;
    cursor: pointer;
    z-index: 1;

    &:hover {
      opacity: 1;
    }
  }
}