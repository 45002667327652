@import '~bootstrap/scss/mixins';
@import "variables";

body {
  background: #1d2a4c;
}

.header.blur-bg {
  background: rgba(29, 42, 76, 0.80);

  .language-switcher select {
    background-color: transparent;
  }
}

.who-we-are-section {
  position: relative;
  z-index: 0;

  .background-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0.4;
    }
  }

  .inner-wrap {
    padding-left: 72px;
    padding-right: 72px;
    min-height: 870px;
    position: relative;
    display: flex;
    align-items: flex-end;
    padding-bottom: 60px;
    @include media-breakpoint-down(lg) {
      min-height: 534px;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 25px;
    }
  }

  .relative {
    position: relative;
  }

  .box-title h2 {
    margin-bottom: 30px;
    @include media-breakpoint-down(lg) {
      margin-bottom: 20px;
    }
  }

  .content {
    font-size: 36px;
    font-weight: 400;
    line-height: 45px;
    color: #F5F5F7;
    @include media-breakpoint-down(lg) {
      font-size: 22px;
      line-height: 32px;
      margin-bottom: 41px;
    }
  }

  .wrap-nav {
    align-items: center;
    display: flex;
    @include media-breakpoint-down(lg) {
      padding-right: 50px;
    }
  }

  .about-navigation {
    display: flex;
    flex-wrap: wrap;

    a,
    button {
      position: relative;
      z-index: 0;
      color: #F5F5F7;
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      padding: 10px 20px;
      letter-spacing: 0.2px;
      display: inline-flex;
      margin: 0 5px 12px;
      background: transparent;
      border: none;

      &:before {
        content: '';
        width: 100%;
        height: 100%;
        transform: skewX(-14deg) scale(1.0);
        border: 1px solid white;
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        @include transition;
      }

      &:hover {
        color: $green;
        transform: scale(1.06);

        &:before {
          border: 1px solid $green;
        }
      }
    }
  }

  .scroll-down {
    position: absolute;
    bottom: calc(50% - 12px);
    right: -16px;
    @include media-breakpoint-down(lg) {
      right: 20px;
      bottom: 40px;
    }

    .wrap {
      position: relative;
      width: 15px;
      height: 24px;
      border: 1px solid white;
      border-radius: 6px;

      &:before {
        content: '';
        width: 1px;
        height: 6px;
        background: #fff;
        position: absolute;
        top: 30%;
        left: calc(50% - 0.5px);
        animation: mover-first 1s infinite alternate;
      }

      &:after {
        content: '';
        width: 1px;
        height: 25px;
        position: absolute;
        bottom: -41px;
        background: #fff;
        left: calc(50% - 0.5px);
        animation: mover 1s infinite alternate;
      }

      .arrow {
        position: absolute;
        bottom: -40px;
        left: calc(50% - 6.5px);
        width: 14px;
        height: 14px;
        border-top: 1px solid #fff;
        border-right: 1px solid #fff;
        margin-right: 60px;
        transform: rotate(135deg);
        animation: mover-second 1s infinite alternate;
      }
    }
  }
}

@-webkit-keyframes mover-first {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-2px);
  }
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-5px);
  }
}

@-webkit-keyframes mover-second {
  0% {
    transform: translateY(0) rotate(135deg);
  }
  100% {
    transform: translateY(-5px) rotate(135deg);
  }
}

/* company-history
---------------------------------------------------*/
.company-history {
  padding-top: 110px;
  padding-bottom: 100px;
  @include media-breakpoint-down(lg) {
	  padding: 50px 15px 40px;
  }

  .box-title h2 {
    margin-bottom: 50px;
  }

  .left-side {
    font-size: 28px;
    font-weight: 400;
    line-height: 42px;
    @include media-breakpoint-down(lg) {
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 20px;
    }

  }

  .right-side {
    p {
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px;
      @include media-breakpoint-down(lg) {
        font-size: 18px;
        line-height: 24px;
      }

    }
  }
}

/* primary-box.empty-box
---------------------------------------------------*/
.primary-box.empty-box {
  min-height: 870px;
  @include media-breakpoint-down(lg) {
    min-height: 466px;
  }

}

/* tm-units.style-2
---------------------------------------------------*/
.tm-units.style-2 {
  padding-top: 96px;
  padding-bottom: 115px;
  @include media-breakpoint-down(xl) {
    padding-top: 40px;
    padding-bottom: 15px;
  }


  .box-title h2 {
    margin-bottom: 90px;
    @include media-breakpoint-down(xl) {
      margin-bottom: 0;
    }
  }

  h3 {
    font-size: 29px;
    font-weight: 600;
    line-height: 38px;
    text-transform: uppercase;
    margin-bottom: 30px;
    @include media-breakpoint-down(lg) {
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 20px;
    }

  }

  p {
    font-size: 22px;
    font-weight: 400;
    line-height: 36px;
    margin-bottom: 36px;
    @include media-breakpoint-down(lg) {
      font-size: 18px;
      line-height: 24px;
    }

  }

  .img-box img {
    object-fit: cover;
    width: 100%;
    height: 520px;
    overflow: hidden;
    @include media-breakpoint-down(xl) {
      height: 420px;
    }
    @include media-breakpoint-down(lg) {
      height: 320px;
    }
  }

  .item-row {
    @include media-breakpoint-down(xl) {
      margin-bottom: 40px;
    }

    &:nth-child(2) {
      margin-bottom: 164px;

      @include media-breakpoint-down(xl) {
        margin-bottom: 40px;
      }

    }

    &:nth-child(3) {
      margin-bottom: 110px;
      @include media-breakpoint-down(xl) {
        margin-bottom: 40px;
      }
    }
  }

  .wrap-arrows-button {
    margin-bottom: 30px;
  }
}

/* ideology
---------------------------------------------------*/
.ideology {
  padding: 0;

  .top-container {
    background: url(../images/content/ideology.webp) no-repeat left top;
    background-size: cover;
    position: relative;
    width: 100%;
    padding: 65px 72px 60px;
    min-height: 559px;
    @include media-breakpoint-down(lg) {
      min-height: 347px;
      background-position: center;
    }
  }

  .bottom-container {
    padding: 107px 72px 30px;
    @include media-breakpoint-down(lg) {
      padding: 40px 15px 15px;
    }
  }

  .box-title h2 {
    margin-bottom: 30px;
  }

  .left-side {
    p {
      font-size: 22px;
      line-height: 36px;
      margin-bottom: 40px;

      @include media-breakpoint-down(lg) {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 20px;
      }
    }

    .img-box {
      @include media-breakpoint-down(lg) {
        margin-bottom: 20px;
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        overflow: hidden;
      }
    }
  }

  .right-side {
    .item {
      margin-bottom: 80px;
    }

    .icon-box {
      margin-bottom: 30px;
      @include media-breakpoint-down(lg) {
        margin-bottom: 20px;
      }
    }

    h3 {
      font-size: 29px;
      font-weight: 600;
      line-height: 38px;
      text-transform: uppercase;
      color: $green;
      margin-bottom: 30px;
      @include media-breakpoint-down(lg) {
        margin-bottom: 20px;
      }
    }

    p {
      font-size: 22px;
      font-weight: 400;
      line-height: 36px;
      @include media-breakpoint-down(lg) {
        font-size: 18px;
        line-height: 24px;
      }

    }
  }
}

/* our-advantages
---------------------------------------------------*/
.our-advantages {
  padding-top: 110px;
  padding-bottom: 100px;
  @include media-breakpoint-down(lg) {
    padding-top: 40px;
    padding-bottom: 0;
  }

  .box-title h2 {
    margin-bottom: 62px;
    @include media-breakpoint-down(lg) {
      margin-bottom: 40px;
    }

  }

  .icon-box {
    margin-bottom: 30px;
  }

  .name {
    font-size: 29px;
    font-weight: 600;
    line-height: 38px;
    text-transform: uppercase;
    margin-bottom: 30px;
    color: $green;
  }

  .description {
    font-size: 22px;
    font-weight: 400;
    line-height: 36px;
    padding-right: 65px;
    @include media-breakpoint-down(lg) {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 80px;
    }
  }

}

/* reviews
---------------------------------------------------*/
.reviews {
  padding-top: 50px;
  padding-bottom: 78px;
  @include media-breakpoint-down(lg) {
    padding-top: 0;
  }

  .swiper {
    max-width: 1298px;
    margin: 0 auto;
    text-align: center;
  }

  .name {
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    text-transform: uppercase;
    color: $green;
    margin-bottom: 40px;
  }

  .description {
    font-size: 36px;
    line-height: 45px;
    margin-bottom: 40px;
  }

  .swiper-pagination {
    font-size: 28px;
    line-height: 56px;
    text-transform: uppercase;
    position: static !important;
    letter-spacing: -1px;
  }

  .swiper-button-prev {
    width: 44px;
    height: 44px;
    font-size: 11px;
    border-radius: 100%;
    border: 1px solid #fff;
    left: calc(50% - 90px);
    top: auto !important;
    bottom: 4px;

    &:after {
      color: white;
      font-size: 12px;
    }
  }

  .swiper-button-next {
    width: 44px;
    height: 44px;
    font-size: 11px;
    border-radius: 100%;
    border: 1px solid #fff;
    right: calc(50% - 90px);
    top: auto !important;
    bottom: 4px;

    &:after {
      color: white;
      font-size: 12px;
    }
  }
}
