html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

// TODO: Should be custom bootstrap file "bootstrap"
@import "~bootstrap/scss/bootstrap";

@import "mixin";
@import "variables";
@import "scroll";
@import "typography";
@import "humburger";
@import "header";
@import "footer";
@import "product-cart-list";
@import "main";
@import "slider";
@import "custom-modal";
@import "about";
@import "utils";
@import "modal-video";
@import "animation-line";
@import "stars";

:root {
  --swiper-navigation-color: rgba(148, 215, 42, 1);
}