@import "variables";
@import "mixin";
@import '~bootstrap/scss/mixins';

a {
  transition: all .3s cubic-bezier(.25, .1, .25, 1);
  text-decoration: none;
  outline: none !important;
  color: $palcheColor;

  &:hover {
    color: $white;
    text-decoration: none;
  }
}

input,
button,
textarea {
  outline: none !important;
  transition: all .3s cubic-bezier(.25, .1, .25, 1);
}

input {
  border-radius: 0;
  margin-bottom: 20px;
}

textarea {
  margin-bottom: 20px;
}

img {
  max-width: 100%;
}

ul {
  padding: 0;
  margin: 0;

  li {
    list-style: none;
  }
}

p {
  margin-bottom: 20px;
  line-height: 19px;
}

/* button
----------------------------------------------------*/
button {
  padding: 4px 12px 5px 12px;
  border: 1px solid $green;
  background-color: white;
  color: $palcheColor;
  font-weight: 400;
  text-align: center;
  border-radius: 2px;
  margin-bottom: 20px;
  display: inline-block;
  min-width: inherit;
  transition: all .3s cubic-bezier(.25, .1, .25, 1);

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

form {
  label {
    color: $white;
    @include px-to-rem(20);
    font-weight: 600;
    line-height: 32px;
    text-transform: uppercase;
    margin-bottom: 17px;
    @include media-breakpoint-down(md) {
      margin-bottom: 5px;
    }
  }

  input {
    height: 46px;
    width: 100%;
    border: none;
    background: none;
    border-bottom: 1px solid $white;
    color: rgba(102, 117, 156, 1);
    padding-left: 0;
  }

  input {
    @include placeholder {
      color: #66759c !important;
    }
  }

  textarea {
    height: 56px;
    width: 100%;
    border: none;
    background: none;
    border-bottom: 1px solid $white;
    color: rgba(102, 117, 156, 1);
    padding-left: 0;
    resize: inherit;
    padding-top: 10px;
  }

  textarea {
    @include placeholder {
      color: #66759c !important;
    }
  }

  .form-action {
    .submit-btn {
      position: relative;
      padding: 10px 20px;
      background: none;
      color: $palcheColor;
      text-align: center;
      @include px-to-rem(14);
      font-weight: 600;
      text-transform: uppercase;
      border: none;

      &:hover {
        transform: scale(1.1);
      }

      &:before {
        content: '';
        width: 100%;
        height: 100%;
        transform: skewX(-15deg);
        background: #94d72a;
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .arrow-right {
      display: inline-flex;
      position: relative;
      margin-left: 5px;
      width: 115px;
      height: 10px;

      &:after {
        content: '';
        width: 115px;
        height: 10px;
        background: url(../images/icons/more-arrow.svg) no-repeat left center;
        display: inline-block;
        margin-left: 10px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

/* green-btn
----------------------------------------------------*/
.white-btn,
.green-btn {
  position: relative;
  padding: 10px 20px;
  background: none;
  color: $palcheColor;
  text-align: center;
  @include px-to-rem(14);
  font-weight: 600;
  text-transform: uppercase;
  border: none;
  z-index: 0;
  width: 100%;

  &:hover {
    transform: scale(1.1);
    background: none;
    color: $palcheColor;
  }

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    transform: skewX(-15deg);
    background: #94d72a;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
  }

  &.d-inline-flex {
    width: auto;
  }
}

.white-btn {
  &:before {
    background: #fff;
    border: 1px solid $palcheColor;
  }

  &.transparent {
    color: $white;

    &:before {
      border: 1px solid $white;
      background: transparent;
      @include transition;
    }

    &:hover {
      color: $palcheColor;

      &:before {
        border-color: $green;
        background: $green;
      }
    }
  }
}

.wrap-arrows-button {
  position: relative;

  &:after {
    content: '';
    width: 115px;
    height: 10px;
    background: url(../images/icons/more-arrow.svg) no-repeat left center;
    display: inline-block;
    margin-left: 10px;
  }

  .green-btn {
    width: auto;
    min-width: 136px;
    margin-right: 5px;
  }

  &.left-arrow {
    &:after {
      display: none;
    }

    &:before {
      content: '';
      width: 115px;
      height: 10px;
      display: inline-block;
      margin-right: 10px;
      background: url(../images/icons/arrow-left-long.svg) no-repeat left center;
    }
  }

  &.arrow-down {
    .green-btn {
      vertical-align: top;
    }

    &:after {
      background: url(../images/icons/arrow-down.svg) no-repeat center;
      width: 10px;
      height: 40px;
    }
  }
}

.tabs-btn {
  border-radius: 2px !important;
  border: 1px solid #E7E9ED !important;
  @include px-to-rem(18);
  font-weight: 400;
  line-height: 20px;
  color: $palcheColor !important;
  background-color: transparent !important;
  text-align: left;
  padding: 15px 18px 15px 53px;
  white-space: nowrap;
  background-position: 15px center !important;
  background-repeat: no-repeat !important;
  margin-bottom: 15px;
  @include media-breakpoint-down(lg) {
    font-size: 0 !important;
    padding-left: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  @media(max-width: 1360px) {
    @include px-to-rem(16);
  }
  @include media-breakpoint-down(md) {
    padding: 10px 10px 10px 35px;
    background-position: 10px center !important;
    align-items: center;
    display: inline-flex;
  }

  &:hover,
  &.active {
    border-color: transparent !important;
    background-color: rgba(148, 215, 42, 0.37) !important;
  }

  &.history-btn {
    background-image: url(../images/icons/history-btn.svg);
  }

  &.service-btn {
    background-image: url(../images/icons/service-btn.svg);
  }

  &.messages-btn {
    background-image: url(../images/icons/messages-btn.svg);
  }

  &.logout-btn {
    background-image: url(../images/icons/logout-icon.svg);
    background-size: 20px;
  }

  .mob-style {
    display: none;
    @include media-breakpoint-down(lg) {
      @include px-to-rem(16);
      display: inline-block;
    }
  }
}

/* underline-btn
----------------------------------------------------*/
.underline-btn {
  color: #1D2A4C;
  @include px-to-rem(16);
  font-weight: 400;
  line-height: 22px;
  min-width: inherit;
  border: none;
  border-bottom: 1px solid $green;
  padding: 0;
  background: none;

  &:hover {
    background: none;
    color: #1D2A4C;
  }
}

/* list-star
----------------------------------------------------*/
.list-star {
  display: flex;

  li {
    margin-right: 20px;

    &:last-of-type {
      margin-right: 0;
    }

    button {
      color: #1D2A4C;
      @include px-to-rem(16);
      line-height: 22px;
      width: 100px;
      background: url(../images/icons/star.svg) no-repeat center top;
      padding-top: 60px;
      text-align: center;
      border: none;
    }
  }
}

/* dropdown
----------------------------------------------------*/
.accordion {
  color: $white;
  border-top: 1px solid $white;
  margin-top: 50px;
  margin-bottom: 80px;

  .accordion-item {
    background: none;
    border: none;
    border-bottom: 1px solid $white;
    border-radius: 0;

    .icon-box {
      margin-right: 10px;
      display: inline-flex;
      align-items: center;
    }

    .count {
      color: #636F8F;
      margin-left: 10px;
      display: inline-flex;
    }
  }

  .accordion-button {
    border: none;
    box-shadow: none;
    background: none;
    border-radius: 0;
    margin-bottom: 0;
    color: $white;
    @include px-to-rem(22);
    font-weight: 600;
    line-height: 45px;
    padding: 20px 20px 20px 0;

    &:after {
      width: 22px;
      height: 16px;
      background: url(../images/icons/accardion-arrows.svg) no-repeat center;
      background-size: contain;
    }

    @include media-breakpoint-down(md) {
      @include px-to-rem(18);
      line-height: 26px;
    }
  }

  .accordion-button:not(.collapsed)::after {

  }

  .accordion-body {
    color: $white;
    @include px-to-rem(22);
    font-weight: 400;
    line-height: 28px;
    padding-top: 0;
    padding-bottom: 40px;
    padding-right: 30%;
    @include media-breakpoint-down(md) {
      @include px-to-rem(18);
      line-height: 26px;
      padding-right: 0;
    }
  }
}

/* style for input[type="radio"], [type="checkbox"]
----------------------------------------------------*/
.wrap-radiobox,
.wrap-checkbox {
  position: relative;
  z-index: 0;
}

input {
  &[type="radio"] {
    left: -9999px;
    position: absolute;
  }

  &[type="checkbox"] {
    left: -9999px;
    position: absolute;

    + label:before {
      background: #fff;
      content: ' ';
      display: inline-block;
      height: 18px;
      position: absolute;
      left: 0;
      top: 2px;
      width: 18px;
      border: 1px solid #E0E0E0;
      @include transition;
    }

    + label:after {
      background: none;
      content: ' ';
      display: inline-block;
      opacity: 0;
      position: absolute;
      left: 7px;
      top: 7px;
      width: 5px;
      height: 7px;
      border: solid $green;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      @include transition;
    }

  }

  &[type="radio"]:checked + label, &[type="checkbox"]:checked + label {
    // color: $black;
  }

  &[type="radio"]:checked + label:before, &[type="checkbox"]:checked + label:before {
    border-color: $green
  }

  &[type="radio"]:checked + label:after, &[type="checkbox"]:checked + label:after {
    opacity: 1;
  }

  &[type="radio"] + label, &[type="checkbox"] + label {
    cursor: pointer;
    margin-left: 4px;
    margin-bottom: 16px;

    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    padding-left: 45px;
    text-transform: inherit;
  }

  &[disabled="disabled"] + label {
    opacity: 0.4;
  }

  &[type="radio"] + label:before {
    background: #fff;
    content: ' ';
    display: inline-block;
    position: absolute;
    left: 0;
    top: 2px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #E0E0E0;
    @include transition;
  }

  &[type="radio"] + label:after {
    background: $green;
    content: ' ';
    display: inline-block;
    height: 18px;
    width: 18px;
    position: absolute;
    left: 3px;
    top: 5px;
    opacity: 0;
    border-radius: 50%;
    @include transition;
  }

}

/* .form-select
----------------------------------------------------*/
.form-select {
  border: none;
  border-radius: 0;
  box-shadow: none !important;
  outline: none !important;
  border-bottom: 1px solid #f5f5f7;
  color: #66759c;
  padding-left: 0;
  height: 46px;
  @include media-breakpoint-down(md) {
    margin-bottom: 15px;
  }
}


/* pagination
----------------------------------------------------*/
.pagination {
  margin-bottom: 100px;
  @include media-breakpoint-down(md) {
    margin-bottom: 60px;
  }

  .page-item {
    margin: 5px;
  }

  .page-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 40px;
    @include px-to-rem(18);
    padding: 0;
    border: none;
    border-radius: 0 !important;
    color: $white;
    background: none !important;
    position: relative;
    z-index: 0;
    box-shadow: none;

    &:before {
      content: '';
      width: 90%;
      height: 100%;
      transform: skewX(-13deg);
      background: $green;
      z-index: -1;
      position: absolute;
      top: 0;
      left: 7%;
      opacity: 0;
      @include transition;
    }

  }

  .page-item {
    &.prev,
    &.next {
      .page-link:before {
        display: none !important;
      }
    }

    &.disabled {
      opacity: 0.7;
    }

    &.active,
    &:hover {
      .page-link {
        color: $palcheColor;

        &:before {
          opacity: 1;
        }
      }
    }
  }
}

.privacy-policy {
  p {
    font-size: 1.25rem;
    line-height: 28px;
  }

  ol {
    counter-reset: item;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      display: table;
      counter-increment: item;
      margin: 0.6em 0;
      font-size: 1.25rem;
      line-height: 28px;

      &:before {
        content: counters(item, ".") ". ";
        display: table-cell;
        padding-right: 0.6em;
      }
    }
  }
}
