@import "variables";
@import "~bootstrap/scss/mixins";

.product-cart-list {
  .product-item {
    display: flex;
    padding-bottom: 20px;
    border-bottom: 1px solid $border-color;
    margin-bottom: 20px;
    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
    }
  }

  .img-box {
    width: 110px;
    height: 110px;
    flex: 0 0 110px;
    margin-right: 20px;
    @include media-breakpoint-down(md) {
      flex: 0 0 100px;
      margin-right: 15px;
    }

    a {
      display: block;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .product-info {
    width: 100%;
    @include media-breakpoint-down(md) {
      width: calc(100% - 115px);
    }
  }

  .product-name {
    margin-bottom: 14px;
    @include media-breakpoint-down(md) {
      margin-bottom: 10px;
    }

    a {
      color: $palcheColor;
      @include px-to-rem(18);
      line-height: 1;
      text-transform: uppercase;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      @include media-breakpoint-down(md) {
        @include px-to-rem(16);
      }
    }
  }

  .art {
    color: $gray;
    leading-trim: both;
    line-height: 20px;
    margin-bottom: 14px;
    @include media-breakpoint-down(md) {
      margin-bottom: 10px;
    }
  }

  .remove-product {
    background: url(../images/icons/trash.svg) no-repeat left center;
    color: #8F95A6;
    line-height: 20px;
    border: none;
    padding: 0 0 0 24px;
    margin-bottom: 0;
  }

  .product-total {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    height: 100%;
    @include media-breakpoint-down(md) {
      width: 100%;
      flex-direction: inherit;
      align-items: center;
      padding-top: 20px;
      height: auto;
    }
  }

  .cost {
    color: $palcheColor;
    @include px-to-rem(18);
    font-weight: 600;
    line-height: 20px;
    white-space: nowrap;
    @include media-breakpoint-down(md) {
      order: 2;
    }
  }

  .cart-counter {
    display: flex;
    align-items: center;
    border: 1px solid $border-color;
    @include media-breakpoint-down(md) {
      order: 1;
    }

    button {
      width: 15px;
      height: 36px;
      border: none;
      text-align: center;
      margin-bottom: 0;
      font-size: 0;

      &.minus {
        background: url(../images/icons/minus-btn.svg) no-repeat center center;
      }

      &.plus {
        background: url(../images/icons/plus-btn.svg) no-repeat center center;
      }
    }

    input {
      min-width: inherit;
      width: 30px;
      margin-bottom: 0;
      text-align: center;
      border: none;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
  }

}

.product-cart {
  .total-sum {
    @include px-to-rem(18);
    align-items: flex-end;
    display: flex;
    @include media-breakpoint-down(md) {
      @include px-to-rem(16);
    }

    .sum {
      text-align: end;
      font-weight: 600;
      line-height: 20px;
    }
  }
}

.cart-footer {
  @include media-breakpoint-down(md) {
    text-align: center;
  }
}