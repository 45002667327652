@import "variables";
@import "mixin";

.custom-modal {
  color: $palcheColor;

  .modal-content {
    border-radius: 0;
    background: white;
    padding-left: 0;
    padding-right: 0;
  }

  &.max-490 {
    .modal-content {
      max-width: 490px;
    }
  }

  &.max-690 {
    .modal-dialog,
    .modal-content {
      max-width: 690px;
    }
  }

  &.max-790 {
    .modal-dialog,
    .modal-content {
      max-width: 790px;
    }
  }

  .modal-header {
    padding: 25px 40px;
    border-color: #E7E9ED;

    .btn-close {
      min-width: inherit;
      background: url(../images/icons/close-modal.svg) no-repeat center;
      width: 22px;
      height: 22px;
      border: none;

      position: absolute;
      top: 30px;
      right: 25px;
      outline: none !important;
    }
  }

  .modal-title {
    @include px-to-rem(27);
    font-weight: 600;
    line-height: 32px;
    text-transform: uppercase;
    position: relative;
    color: $palcheColor;
    display: flex;
    align-items: center;

    &:before {
      content: '';
      width: 37px;
      height: 20px;
      background: url(../images/icons/stripes-title.svg) no-repeat center/cover;
      position: absolute;
      left: -40px;
    }
  }

  label {
    color: #212529;
    display: block;
    @include px-to-rem(16);
    font-weight: 600;
    line-height: 32px;
    text-transform: uppercase;
    margin-bottom: 5px;

    span {
      color: $green;
    }
  }

  textarea,
  input {
    height: 46px;
    @include px-to-rem(16);
    border: none;
    border-bottom: 1px solid rgba(167, 173, 188, 1);
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  textarea {
    height: auto;
    resize: inherit;
  }

  p {
    line-height: 22px;
    margin-bottom: 15px;
  }

  .modal-body,
  .modal-footer {
    padding-left: 30px;
    padding-right: 30px;
    border: none;
  }

  &.video {
    .modal-header {
      min-height: 40px;
      border: none;

      .btn-close {
        background: url(../images/icons/close-modal-white.svg) no-repeat center;
        top: 0;
      }
    }

    .modal-dialog,
    .modal-content {
      max-width: 1000px;
      background: transparent;
    }

    .modal-body {
      padding: 0;
    }
  }
}
