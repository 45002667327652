@import '~bootstrap/scss/mixins';
@import "mixin";

.footer {
  background: rgba(11, 18, 36, 1);
  padding: 40px 67px 30px;
  @include media-breakpoint-down(xl) {
    padding-left: 25px;
    padding-right: 25px;
  }
  @include media-breakpoint-down(lg) {
    padding-left: 5px;
    padding-right: 5px;
  }

  .left-side {
    display: flex;
    @include media-breakpoint-down(md) {
      display: block;
    }
  }

  .logo-wrapper {
    margin-right: 27px;
    max-width: 128px;
    @include media-breakpoint-down(md) {
      margin-bottom: 30px;
      img {
        max-width: 127px;
        height: auto;
      }
    }
  }

  .footer-menu {
    width: 100%;

    ul {
      display: flex;
      flex-wrap: wrap;
      @include media-breakpoint-down(md) {
        display: block;
      }

      li {
        @include media-breakpoint-down(md) {
          margin-bottom: 14px;
          display: block;
        }
      }

      .nav-link {
        position: relative;
        color: #F5F5F7;
        @include px-to-rem(14);
        letter-spacing: 0.56px;
        text-transform: uppercase;
        padding: 3px 1px;
        margin-left: 10px;
        margin-right: 10px;
        @include media-breakpoint-down(md) {
          margin: 0;
          display: inline-flex;
        }

        &:after {
          content: '';
          width: 0;
          height: 2px;
          background: #94d72a;
          position: absolute;
          bottom: 0;
          left: 0;
          transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
        }

        &.active:after,
        &:hover:after {
          width: 100%;
        }
      }
    }
  }

  .info-links {
    justify-content: flex-end;
    display: flex;
    @include media-breakpoint-down(md) {
      justify-content: start;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      @include media-breakpoint-down(md) {
        display: block;
        margin-bottom: 10px;
        li {
          margin-bottom: 14px;
        }
      }
    }

    a {
      color: #F5F5F7;
      @include px-to-rem(14);
      letter-spacing: 0.56px;
      text-transform: uppercase;
      padding: 3px 1px;
      margin-left: 10px;
      margin-right: 10px;
      @include media-breakpoint-down(md) {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  /* bottom-line
  -------------------------------------*/
  .bottom-line {
    padding-top: 27px;
    @include px-to-rem(14);
    color: #A5ADC3;

    .right-side {
      display: flex;
      justify-content: flex-end;
      @include media-breakpoint-down(md) {
        margin-top: 30px;
        justify-content: start;
      }
    }
  }
}