@import "variables";

.menu-button {
  width: 30px;
  height: 30px;
  min-width: 30px;
  margin: 0;
  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  background: none !important;
  border: none !important;
  border-radius: 0;
  box-shadow: none !important;
}

.menu-button span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: $white;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

.menu-button span:nth-child(1) {
  top: 7px;
}

.menu-button span:nth-child(2), .menu-button span:nth-child(3) {
  top: 15px;
}

.menu-button span:nth-child(4) {
  top: 23px;
}

.menu-button.open span {
  background: white;
}

.menu-button.open span:nth-child(1) {
  top: 18px;
  width: 0;
  left: 50%;
}

.menu-button.open span:nth-child(2) {
  transform: rotate(45deg);
}

.menu-button.open span:nth-child(3) {
  transform: rotate(-45deg);
}

.menu-button.open span:nth-child(4) {
  top: 18px;
  width: 0;
  left: 50%;
}
