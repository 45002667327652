@import '~bootstrap/scss/mixins';

$mainColor: #1d2a4c;
$greenColor: #1d2a4c;

@mixin scroll($thumb: $greenColor, $track: rgba($greenColor, .3), $width: 6px) {
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  scrollbar-color: $thumb $track;
  &::-webkit-scrollbar {
    width: $width;
    height: $width;
  }
  &::-webkit-scrollbar-track {
    background: $track;
  }
  &::-webkit-scrollbar-thumb {
    background: $thumb;
  }
}

.scroll-wrap {
  padding: 0 0 10px 10px;
  width: 100%;
  max-height: 584px;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  scrollbar-color: $mainColor rgba($mainColor, .2);
  @include media-breakpoint-down(lg) {
    max-height: 480px;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: rgba($mainColor, .3);
  }

  &::-webkit-scrollbar-thumb {
    background: $mainColor;
  }
}