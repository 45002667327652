@mixin placeholder {
  &::-webkit-input-placeholder {
    @content
  }
  &:-moz-placeholder {
    @content
  }
  &::-moz-placeholder {
    @content
  }
  &:-ms-input-placeholder {
    @content
  }
}

@mixin transition {
  transition: all .3s cubic-bezier(.25, .1, .25, 1);
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

/* px to rem */
$main-font-size: 16px;

@mixin rem($property, $values) {
  $font-size: $main-font-size;
  $px-values: ();
  $rem-values: ();

  @each $value in $values {
    @if $value == 0 or $value == 0px {
      $px-values: join($px-values, 0);
      $rem-values: join($rem-values, 0);
    } @else if type-of($value) == number and not unitless($value) and (unit($value) == px) {
      $new-rem-value: $value / $font-size;
      $px-values: join($px-values, round($value));
      $rem-values: join($rem-values, #{$new-rem-value}rem);
    } @else if type-of($value) == number and not unitless($value) and (unit($value) == "%") {
      $px-values: join($px-values, #{$value});
      $rem-values: join($rem-values, #{$value});
    } @else if $value == auto {
      $px-values: join($px-values, auto);
      $rem-values: join($rem-values, auto);
    } @else {
      $px-values: join($px-values, round($value * $font-size));
      $rem-values: join($rem-values, #{$value}rem);
    }
  }

  #{$property}: $px-values;
  #{$property}: $rem-values;
}

@mixin px-to-rem($pxValue, $property: "font-size", $standard-size: 16) {
  #{$property}: $pxValue + px;
  #{$property}: $pxValue/$standard-size + rem;
}