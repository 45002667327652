@import '~bootstrap/scss/mixins';
@import "mixin";
@import "variables";

.header {
  background: #1D2A4C;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  padding: 30px 60px;
  @include media-breakpoint-down(xl) {
    padding: 30px 40px;
  }
  @include media-breakpoint-down(lg) {
    padding: 15px;
  }
  @include media-breakpoint-down(md) {
    padding: 15px 0;
  }

  .header-element-list {
    justify-content: space-between;
    @include media-breakpoint-down(xl) {
      justify-content: flex-end;
    }
  }

  /* logo-wrapper
  -------------------*/
  .logo-wrapper {
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    order: 1;
    @include media-breakpoint-down(xl) {
      order: 2;
      margin-right: auto;
    }

    img {
      height: auto;
      max-width: 210px;
      width: 100%;
      @media(min-width: 1200px) and (max-width: 1290px) {
        max-width: 130px;
      }
      @media(min-width: 1390px) {
        width: 250px;
      }
      @include media-breakpoint-down(xl) {
        max-width: 210px;
      }
      @include media-breakpoint-down(md) {
        max-width: 100px;
      }
    }
  }

  /* navbar
  -------------------*/
  .wrapper-navbar {
    position: relative;
    z-index: 1;
    order: 2;
    @include media-breakpoint-down(xl) {
      order: 1;
    }

    .navbar-toggler,
    .navbar {
      padding: 0;
    }

    .nav-link {
      padding: 3px 1px;
      color: $white;
      @include px-to-rem(14);
      letter-spacing: 0.5px;
      text-transform: uppercase;
      white-space: nowrap;
      position: relative;
      margin-left: 10px;
      margin-right: 10px;
      @media(max-width: 1360px) {
        @include px-to-rem(13);
        margin-left: 8px;
        margin-right: 8px;
      }
      @include media-breakpoint-down(xl) {
        @include px-to-rem(18);
      }

      &:after {
        content: '';
        width: 0;
        height: 2px;
        background: $green;
        position: absolute;
        bottom: 0;
        left: 0;
        @include transition;
        @include media-breakpoint-down(xl) {
          display: none;
        }
      }

      &:hover,
      &.active {
        color: $white;

        &:after {
          width: 100%;
        }
      }
    }

    .navbar-toggler {
      position: relative;
    }

    .navbar .navbar-collapse {
      @include media-breakpoint-down(xl) {
        position: fixed;
        top: 90px;
        left: 0;
        width: 100%;
        background: #1D2A4C;
        z-index: 99;

        @include media-breakpoint-down(lg) {
          top: 70px;
        }

        @include media-breakpoint-down(md) {
          top: 60px;
        }

        .wrap-navbar {
          height: calc(100vh - 76px);
          padding-top: 15px;
          overflow-x: auto;
          position: relative;
          @include media-breakpoint-down(md) {
            height: calc(100vh - 60px);
          }

          .col {
            @include media-breakpoint-down(xl) {
              flex: 0 0 auto !important;
            }
          }

          .top-line {
            @include media-breakpoint-down(xl) {
              display: flex;
              flex-wrap: wrap;
              padding-left: 15px;
              padding-right: 15px;
              align-items: center;
              padding-bottom: 15px;
              margin-top: 58px;
            }

            .user-menu {
              order: 1;
              flex: 0 0 100% !important;
              margin: 0 0 15px;
              padding-left: 10px;

              a {
                color: #f5f5f7;
              }
            }

            .phone {
              order: 2;
              display: flex;
              flex: 0 0 100% !important;
              margin-bottom: 58px;
              padding-left: 10px;
            }

            .order-call {
              order: 3;
              margin-right: 40px;
              margin-left: 20px;
            }

            .language-switcher {
              order: 4;
            }
          }
        }

        .inner-toggler {
          position: absolute;
          top: 15px;
          right: 15px;
        }

        .navbar-nav {
          order: 10;
          width: 100%;
          @include media-breakpoint-down(xl) {
            padding-left: 15px;
            padding-right: 15px;
            margin-top: 20px;
          }
        }

      }
    }

  }

  /* language-switcher
  -------------------*/
  .language-switcher {
    order: 3;
    @include media-breakpoint-down(lg) {
      display: none;
    }

    select {
      background: transparent;
      border: none;
      color: $white;
      box-shadow: none;
      outline: none;
      padding-right: 20px;
      height: auto;
      background: $palcheColor url(../images/icons/select-arrow.svg) calc(100% - 5px) center no-repeat;
    }
  }

  /* order-call
  -------------------*/
  .order-call {
    position: relative;
    z-index: 0;
    margin-left: 10px;
    margin-right: 10px;
    @include transition;
    order: 4;
    @include media-breakpoint-down(xl) {
      align-items: center;
      display: flex;
      margin-right: auto;
    }
    @include media-breakpoint-down(lg) {
      display: none;
    }

    &:hover {
      @include media-breakpoint-up(xl) {
        transform: scale(1.1);
      }
    }

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      transform: skewX(-15deg);
      background: $green;
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
    }

    @include media-breakpoint-down(xl) {
      order: 1;
    }

    a,
    button {
      color: $palcheColor;
      font-weight: 600;
      @include rem(font-size, 16px);
      background: transparent url(../images/icons/phone-blue.svg) no-repeat left center;
      display: inline-flex;
      text-transform: uppercase;

      // Reset button
      border: none;
      padding: 6px 0 6px 28px;
      margin-bottom: 0;

      &:hover {
        color: $palcheColor;
      }

      @media(max-width: 1830px) {
        @include px-to-rem(14);
      }

      @media(max-width: 1640px) {
        @include px-to-rem(0);
        margin-bottom: 12px;
        min-height: 20px;
        background-position: center;
      }

      @include media-breakpoint-down(xl) {
        @include rem(font-size, 14px);
        background-position: left center; // TODO: Should be fix
        background-position: 12px center;
        padding-left: 40px;
        margin-bottom: 0;
        padding-right: 15px;
      }
    }
  }

  /* phone
  -------------------*/
  .phone {
    order: 5;
    @media(max-width: 1790px) {
      display: none;
    }
    @include media-breakpoint-down(xl) {
      order: 2;
    }

    a {
      @include px-to-rem(16);
      color: $white;
      @media(max-width: 1830px) {
        @include px-to-rem(14);
      }
    }
  }

  /* user-menu
  -------------------*/
  .user-menu {
    order: 6;
    @include media-breakpoint-down(xl) {
      order: 2;
      margin-right: 15px;
    }
    @include media-breakpoint-down(lg) {
      margin-right: 0;
    }

    ul {
      display: flex;
      margin-left: -5px;
      margin-right: -5px;
      padding: 0;

      li {
        padding-left: 5px;
        padding-right: 5px;

        button {
          color: $white;

          &:hover {
            opacity: 0.8;
          }
        }

        &.hide-for-collapse {
          @include media-breakpoint-down(xl) {
            display: none;
          }
        }

        img.close-icon {
          display: none;
          max-width: 20px;
        }

        .open-search {
          &.open {
            img.close-icon {
              display: block;
            }

            img.search-icon {
              display: none;
            }
          }
        }
      }
    }

    &.visible-for-collapse {
      display: none;
      @include media-breakpoint-down(xl) {
        display: block;
      }
    }
  }

  .search-form {
    display: none;
    position: absolute;
    left: 0;
    width: 100%;
    transform: translateY(-200%);
    @include transition;
    z-index: 3;
    height: 100%;
    align-items: center;
    top: 0;
    color: white;
    background: #94d72a;

    &.open {
      display: flex;
      transform: translateY(0);
    }

    form {
      position: relative;
      z-index: 0;
      width: 100%;
      padding-right: 15px;
    }

    input {
      padding-right: 50px;
      position: relative;
      z-index: 0;
      color: $white;
      margin: 0;
      font-size: 20px;
    }

    input {
      @include placeholder {
        color: white !important;
      }
    }

    .search-btn {
      width: 30px;
      height: 30px;
      background: url(../images/icons/close-search.svg) center center no-repeat;
      margin-bottom: 0;
      border: none;
      box-shadow: none;
      position: absolute;
      right: 15px;
      bottom: 10px;
      z-index: 1;
      min-width: inherit;
    }
  }

  .move-elements {
    @include media-breakpoint-down(xl) {
      flex: 0 0 auto !important;
    }

  }
}
