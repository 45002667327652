$palcheColor: rgba(29, 42, 76, 1);
$white: rgba(245, 245, 247, 1);
$black: #3A4047;
$blue: #1AB0CE;
$gray: #8F95A6;;
$yellow: #FFCE54;
$green: rgba(148, 215, 42, 1);
$red: #DB0404;
$border-color: #E7E9ED;

$palcheFont: var(--font-commissioner);

$font-family-sans-serif: var(--font-commissioner) !default;
$font-family-monospace: var(--font-commissioner) !default;
