@import '~bootstrap/scss/mixins';

.invalid-feedback {
  position: absolute;
  bottom: -3px;
}

.cursor-pointer {
  cursor: pointer;
}

.mobile-login-button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
}

.mobile-language-switcher {
  select {
    padding: 0;
    margin: 0;
  }
}

.pt-60 {
  padding-top: 60px;
}

.pb-90 {
  padding-bottom: 90px;
  @include media-breakpoint-down(md) {
    padding-bottom: 80px;
  }
}

.mb-120 {
  margin-bottom: 120px;
}

.invalid-feedback {
  position: absolute;
  bottom: 24px;
  font-size: 13px;
  color: #F56363;
}

.error-message {
  position: absolute;
  bottom: 0;
  font-size: 13px;
  color: #F56363;
}

.marker-label {
  position: relative;
  vertical-align: middle;
  top: -3px;
}

.pt-140 {
  padding-top: 140px;
}

.bg-gray {
  background: #F5F5F7;

  .box-title h1, .box-title .h1 {
    color: $palcheColor;
  }
}

.min-width-100 {
  min-width: 100px;
}

.fsc-3 {
  font-size: 0.8em;
}

.store-promo-code {
  background: url(../images/icons/search.svg) no-repeat center;
  background-size: 24px 24px;
  width: 40px;
  background-color: $palcheColor;

  &:hover {
    background-color: $green;
  }
}

.remove-promo-code {
  background: url(../images/icons/close.svg) no-repeat center;
}

.bg-light-green {
  background-color: $green;
  color: $palcheColor;

  &:hover {
    background-color: $green;
  }
}

.bg-dark-green {
  background-color: rgb(86, 140, 0);
  color: $palcheColor;

  &:hover {
    background-color: rgb(86, 140, 0);
  }
}

.red-price {
  color: $red;
}

.truncate-overflow {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}