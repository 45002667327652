@import "variables";
@import "mixin";
@import '~bootstrap/scss/mixins';

* {
  margin: 0;
  padding: 0;
}

::selection {
  color: $white;
  background-color: $green;
}

body {
  background: $palcheColor;
  font-family: $palcheFont;
  color: $white;
  overflow-x: hidden;
  @include px-to-rem(16);

  &.hideOverflow {
    @media (max-width: 991px) and (min-width: 768px) {
      position: fixed !important;
      top: 0;
      left: 0;
      height: 100%;
      right: 0;
      bottom: 0;
    }
  }

  background: $palcheColor;

  @include media-breakpoint-down(md) {
    background: $palcheColor;
  }

  &.white-bg {
    background: $white;
  }

  &.color-black {
    color: $palcheColor;
  }
}

.wrapper {
  position: relative;
  @media (min-width: 992px) {
    overflow: hidden;
  }
}

section {
  position: relative;
}

main {
  > section,
  > div {
    padding-left: 72px;
    padding-right: 72px;
    @include media-breakpoint-down(xl) {
      padding-left: 40px;
      padding-right: 40px;
    }
    @include media-breakpoint-down(lg) {
      padding-left: 15px;
      padding-right: 15px;
    }
    @include media-breakpoint-down(md) {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.box-pdd {
  padding-left: 72px;
  padding-right: 72px;
  @include media-breakpoint-down(xl) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @include media-breakpoint-down(lg) {
    padding-left: 15px;
    padding-right: 15px;
  }
  @include media-breakpoint-down(md) {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.box-title {
  h1 {
    color: #F5F5F7;
    @include px-to-rem(60);
    font-weight: 600;
    line-height: 67px;
    @include px-to-rem(26, margin-bottom);
    @include media-breakpoint-down(xl) {
      @include px-to-rem(50);
      line-height: 56px;
    }
    @include media-breakpoint-down(lg) {
      @include px-to-rem(32);
      line-height: 36px;
    }
  }

  h2 {
    position: relative;
    @include px-to-rem(60);
    font-weight: 600;
    line-height: 60px;
    text-transform: uppercase;
    margin-bottom: 50px;
    max-width: calc(100% - 270px);
    @include media-breakpoint-down(xl) {
      max-width: 100%;
    }
    @include media-breakpoint-down(lg) {
      @include px-to-rem(32);
      line-height: 36px;
    }
    @include media-breakpoint-down(md) {
      margin-bottom: 30px;
    }

    span {
      color: $green;
    }

    &.black-style {
      color: $palcheColor;
    }
  }

  .green-stripes {
    position: relative;

    &:before {
      content: '';
      background: url(../images/icons/stripes-title.svg) no-repeat center left;
      width: 82px;
      height: 43px;
      position: absolute;
      top: 11px;
      left: -95px;
      @include media-breakpoint-down(lg) {
        width: 82px;
        height: 23px;
        top: 8px;
        left: -60px;
        background: url(../images/icons/stripes-title.svg) no-repeat center center;
        background-size: contain;
      }
    }

    span {
      color: $green;
    }

    &.black-style {
      color: $palcheColor;
    }
  }

  .mw-auto {
    max-width: inherit !important;
  }

  .count-reviews {
    color: #636F8F;
    margin-left: 10px;
  }

  .big-text {
    color: $white;
    max-width: 890px;
    @include px-to-rem(36);
    line-height: 45px;
    @include media-breakpoint-down(md) {
      @include px-to-rem(22);
      line-height: 32px;
    }

    a {
      color: $white;
      text-decoration: underline !important;
      text-decoration-color: $green !important;
    }
  }

  //input.search-input {
  //  background: transparent;
  //  border: none;
  //  border-bottom: 1px solid #fff;
  //  margin-bottom: 0;
  //  width: 100%;
  //  max-width: 100%;
  //  font-size: 26px;
  //  color: white;
  //  line-height: 46px;
  //}
  //input.search-input { @include placeholder {
  //  color: white !important;
  //}}
}

.breadcrumb {
  position: relative;
  margin-bottom: 30px;

  li {
    margin-right: 5px;

    &:before {
      display: none;
    }
  }

  a {
    @include px-to-rem(14);
    line-height: 24px;
    text-transform: uppercase;
    color: $white;

    &:before {
      content: '';
      display: inline-block;
      margin-right: 11px;
      width: 20px;
      height: 10px;
      background: url(../images/icons/back-arrows.svg) no-repeat center center;
    }
  }
}

.open-all {
  position: absolute;
  top: -90px;
  right: 30px;
  display: flex;
  align-items: center;
  @include media-breakpoint-down(xl) {
    position: static;
    margin-top: 30px;
    margin-left: 20px;
  }

  &:after {
    content: '';
    width: 115px;
    height: 10px;
    background: url(../images/icons/more-arrow.svg) no-repeat left center;
    display: inline-block;
    margin-left: 10px;
  }

  a {
    position: relative;
    display: inline-flex;
    padding: 5px 15px;
    z-index: 0;
    @include px-to-rem(14);
    font-weight: 600;
    text-transform: uppercase;
    color: $palcheColor;

    &:after {
      content: '';
      width: 100%;
      height: 100%;
      background: $green;
      transform: skewX(-15deg);
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:hover {
      transform: scale(1.1);
    }
  }
}

.pt-150 {
  padding-top: 152px;
  @include media-breakpoint-down(lg) {
    padding-top: 122px;
  }
  @include media-breakpoint-down(md) {
    padding-top: 110px;
  }
}

.pt-100 {
  padding-top: 106px;

  @include media-breakpoint-down(md) {
    padding-top: 96px;
  }
}

.pb-150 {
  padding-bottom: 150px;
  @include media-breakpoint-down(md) {
    padding-bottom: 75px;
  }
}

/* manager-info
-----------------------------*/
.manager-info {
  display: flex;
  align-items: center;
  @include media-breakpoint-down(md) {
    display: none;
  }

  .img-box {
    width: 100px;
    height: 100px;
    margin-right: 15px;
    position: relative;

    .circle-wrap {
      border-radius: 50%;
      overflow: hidden;
      border: 2px solid white;
      z-index: 0;
      position: relative;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    .status {
      position: absolute;
      bottom: 0;
      right: 5px;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      border: 2px solid white;
      z-index: 1;

      &.on-line {
        background: $green;
      }

      &.of-line {
        background: $red;
      }
    }
  }

  .content {
    width: calc(100% - 115px);

    .your-manager {
      color: #8F95A6;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: 0.3px;
      margin-bottom: 7px;
    }

    .name-manager {
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.32px;
      text-transform: uppercase;
      color: #1D2A4C;
      margin-bottom: 5px;
    }

    .tel-manager {
      color: #1D2A4C;
    }
  }
}

/* primary-box
-----------------------------*/
.primary-box {
  padding-bottom: 60px;

  video.video {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .content {
    height: 870px;
    max-height: 90vh;
    align-items: end;
    @include media-breakpoint-down(lg) {
      height: 535px;
    }
    @include media-breakpoint-down(md) {
      height: 470px;
    }

    .see-presentation {
      display: inline-flex;
      position: relative;
      color: $palcheColor;
      text-transform: uppercase;
      @include px-to-rem(18);
      font-weight: 600;
      padding: 6px 29px 8px;
      @include transition;

      &:before {
        content: '';
        width: 100%;
        height: 100%;
        transform: skewX(-15deg);
        background: #94d72a;
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
      }

      &:hover {
        transform: scale(1.1);
      }
    }

    .video-buttons {
      @include media-breakpoint-down(md) {
        display: none !important;
      }
    }

    .video-play-pause {
      border: none;
      padding: 0;
      width: 30px;
      height: 30px;
      background: url(../images/icons/pause.svg) no-repeat center;
      @include transition;

      &:hover {
        opacity: 0.8;
      }

      &.play-my-video {
        background: url(../images/icons/play.svg) no-repeat center;
      }
    }

    .scroll-down {
      background: url(../images/icons/scroll.svg) no-repeat right bottom;
      height: 65px;
      @include media-breakpoint-down(md) {
        background: url(../images/icons/scroll.svg) no-repeat calc(100% - 20px) bottom;
        height: 65px;
      }
    }
  }
}

/* slider
-----------------------------*/
.main-slider {
  .item-slide {
    @media (max-width: 1099px) {
      max-width: 80%;
      margin: 0 auto;
    }
    @include media-breakpoint-down(lg) {
      max-width: 80%;
      margin: 0 auto;
    }
    @include media-breakpoint-down(md) {
      max-width: 90%;
    }
  }
}

/* promotions-and-sales
-----------------------------*/
.promotions-and-sales {
  margin-bottom: 100px;
  @include media-breakpoint-down(lg) {
    margin-bottom: 35px;
  }

  .box-title h2 {
    margin-bottom: 50px;
  }

  .carousel-action {
    .item {
      display: flex;
      height: auto;

      .inner-wrap {
        background: rgba(217, 217, 217, 1);
        height: 100%;
        width: 100%;
        margin: 0 15px;
        position: relative;
        z-index: 0;
      }

      .read-more {
        font-size: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;

        &:hover {
          transform: scale(1.1);
        }
      }

      .top-content {
        position: relative;
        padding: 10px;

        .label {
          @include px-to-rem(14);
          font-style: normal;
          font-weight: 600;
          line-height: 15px;
          text-transform: uppercase;
          position: absolute;
          top: 20px;
          left: -12px;
          height: auto;
          display: inline-flex;
          align-items: center;
          z-index: 0;
          color: $palcheColor;
          padding: 6px 16px 6px 12px;
          max-width: 90%;

          .bg-layout-1 {
            content: '';
            width: 80%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 20%;
            z-index: -1;

            transform: skewX(-15deg);
            background: #94d72a;
          }

          .bg-layout-2 {
            content: '';
            width: 30%;
            height: 100%;
            background: #94d72a;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -2;
          }

          .bg-layout-3 {
            width: 12px;
            height: 100%;
            transform: skewY(35deg);
            background: #69ab00;
            position: absolute;
            top: 5px;
            left: 0;
            z-index: -3;

            &:before {
              content: "";
              width: 100%;
              height: 100%;
              background: rgba(0, 0, 0, 0.2);
              display: block;
            }
          }
        }

        .type-of-drive {
          position: absolute;
          left: 14px;
          bottom: 6px;
          width: 33px;
          height: 33px;

          &.motor-type {
            background: url(../images/icons/diesel-blue.svg) no-repeat left top;
          }

          &.fuel-type {
            background: url(../images/icons/gasoline-blue.svg) no-repeat left top;
          }

          &.electric-type {
            background: url(../images/icons/electric-blue.svg) no-repeat left top;
          }
        }

        .img-box {
          height: 16vw;
          max-height: 295px;
          width: 100%;

          @include media-breakpoint-down(lg) {
            min-height: 170px;
          }
          @include media-breakpoint-down(md) {
            min-height: 270px;
          }

          img {
            object-fit: contain;
            width: 100%;
            height: 100%;
            @include transition;
          }

          img:nth-child(2) {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
          }
        }
      }

      &:hover,
      &.active {
        .img-box.with-hover {
          img:nth-child(1) {
            opacity: 0;
          }

          img:nth-child(2) {
            opacity: 1;
          }
        }
      }

      .bottom-content {
        display: flex;
        padding-bottom: calc(var(--bs-gutter-x) * .6);
        margin-right: calc(var(--bs-gutter-x) * .3);
        margin-left: calc(var(--bs-gutter-x) * .3);
      }

      .title-box {
        width: 60%;
        padding-right: calc(var(--bs-gutter-x) * .3);
        padding-left: calc(var(--bs-gutter-x) * .3);
        @include px-to-rem(16);
        font-weight: 400;
        line-height: 20px;
        text-transform: uppercase;
        color: $palcheColor;

        a {
          @include px-to-rem(16);
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          text-transform: uppercase;
          color: $palcheColor;
        }
      }

      .price-box {
        width: 40%;
        padding-right: calc(var(--bs-gutter-x) * .3);
        padding-left: calc(var(--bs-gutter-x) * .3);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;

        .price {
          @include px-to-rem(16);
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          color: $palcheColor;

          &.red-price {
            color: $red;
          }
        }

        .old-price {
          color: #ADADAD;
          @include px-to-rem(14);
          font-style: normal;
          font-weight: 400;
          line-height: 19px;
          text-decoration: line-through;
          margin-bottom: 5px;
        }
      }
    }
  }
}

/* catalog-of-products
-----------------------------*/
.catalog-of-products {
  margin-top: 170px;

  @include media-breakpoint-down(lg) {
    margin-top: 100px;
    margin-bottom: 35px;
  }

  .box-title h2 {
    margin-bottom: 50px;
  }

  @include media-breakpoint-down(md) {
    .box-title h2 {
      margin-bottom: 0;
    }
  }
}

/* top-news
-------------------------------------------*/
.top-news {
  padding-bottom: 80px;

  @include media-breakpoint-down(lg) {
    padding-bottom: 50px;
  }

  @include media-breakpoint-down(md) {
    padding-bottom: 20px;
  }

  .news-item {
    position: relative;
    overflow: hidden;
    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
    }

    .img-box {
      height: 480px;
      position: relative;
      z-index: 0;
      overflow: hidden;
      @include media-breakpoint-down(xxl) {
        height: 430px;
      }
      @include media-breakpoint-down(lg) {
        height: 330px;
      }
      @include media-breakpoint-down(md) {
        height: 270px;
      }

      img {
        @include transition;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .date {
      position: absolute;
      top: 25px;
      left: 40px;
      color: #F5F5F7;
      @include px-to-rem(18);
      font-weight: 400;
      line-height: 26px;
      @include media-breakpoint-down(lg) {
        left: 30px;
      }
    }

    .content {
      background: rgba(29, 42, 76, 0.8);
      position: absolute;
      bottom: 0;
      width: calc(100% - 1.5rem);
      z-index: 1;
      padding: 40px;
      transform: translateY(70px);
      @include transition;

      @include media-breakpoint-down(xl) {
        padding: 30px;
      }
      @include media-breakpoint-down(lg) {
        transform: translateY(50px);
        padding: 30px;
      }

      .title {
        @include px-to-rem(36);
        font-weight: 600;
        margin-bottom: 24px;
        @include media-breakpoint-down(xl) {
          @include px-to-rem(27);
          line-height: 30px;
          margin-bottom: 10px;
        }
        @include media-breakpoint-down(lg) {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }

      .description {
        color: #F5F5F7;
        @include px-to-rem(18);
        font-weight: 400;
        line-height: 26px;
        margin-bottom: 20px;
        @include media-breakpoint-down(lg) {
          line-height: 20px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          margin-bottom: 10px;
        }
      }

      .read-more {
        position: relative;
        @include px-to-rem(16);
        font-weight: 600;
        text-transform: uppercase;
        z-index: 0;
        color: $palcheColor;
        padding: 5px 15px;
        display: inline-flex;
        opacity: 0;
        @include transition;
        margin-left: 5px;
        @include media-breakpoint-down(lg) {
          @include px-to-rem(14);
          line-height: 30px;
          padding: 3px 10px;
        }

        &:before {
          content: '';
          width: 100%;
          height: 100%;
          transform: skewX(-15deg);
          background: #94d72a;
          z-index: -1;
          position: absolute;
          top: 0;
          left: 0;
        }

        &:hover {
          transform: scale(1.1);
        }
      }
    }

    &:hover {
      .img-box img {
        transform: scale(1.05);
      }

      .content {
        transform: translateY(0);

        .read-more {
          opacity: 1;
        }
      }
    }
  }

  .all-news-link {
    display: block;
    width: auto;
    top: 20px;
    right: 0;
    @include media-breakpoint-down(xl) {
      margin-left: 10px;
    }
  }
}

/* we-work-for-your
-------------------------------------------*/
.we-work-for-your {
  padding: 0;

  .top-container {
    background: url(../images/bg/we-work-bg.webp) no-repeat left top;
    background-size: cover;
    position: relative;
    width: 100%;

    padding: 65px 72px 60px;
    @include media-breakpoint-down(xl) {
      padding-left: 40px;
      padding-right: 40px;
    }
    @include media-breakpoint-down(lg) {
      padding-left: 15px;
      padding-right: 15px;
    }
    @include media-breakpoint-down(md) {
      padding: 20px 5px 46px;
      background-position: top center;
    }

    &:before {
      content: '';
      width: 282px;
      height: 43px;
      background: url(../images/icons/we-work-label.svg) no-repeat right center;
      position: absolute;
      top: 20px;
      right: 0;
      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    .box-title {
      margin-bottom: 140px;
      @include media-breakpoint-down(md) {
        margin-bottom: 58px;
      }

      h2:before {
        display: none;
      }
    }

    .description {
      color: $white;
      @include px-to-rem(18);
      font-weight: 400;
      line-height: 26px;
      max-width: 370px;
      margin-bottom: 23px;
    }

    .order-call {
      position: relative;
      z-index: 0;
      display: inline-flex;
      align-items: center;

      &:after {
        content: '';
        width: 115px;
        height: 10px;
        background: url(../images/icons/more-arrow.svg) no-repeat left center;
        display: inline-block;
        margin-left: 10px;
        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      a {
        color: $palcheColor;
        font-weight: 600;
        @include px-to-rem(16);
        display: inline-flex;
        text-transform: uppercase;
        position: relative;
        padding: 5px 15px 7px 40px;

        &:before {
          content: '';
          width: 100%;
          height: 100%;
          transform: skewX(-15deg);
          // background: #94d72a;
          background: #94d72a url(../images/icons/phone-blue.svg) no-repeat 15px center;
          z-index: -1;
          position: absolute;
          top: 0;
          left: 0;
        }

        &:hover {
          transform: scale(1.1);
        }
      }
    }

    .logo-box {
      position: absolute;
      right: 40px;
      bottom: 40px;
      @include media-breakpoint-down(md) {
        right: 20px;
        bottom: 46px;
        width: 33px;
      }
    }

  }

  .bottom-container {
    padding: 70px 72px 100px;
    background: $white;
    color: $palcheColor;

    @include media-breakpoint-down(xl) {
      padding-left: 40px;
      padding-right: 40px;
    }
    @include media-breakpoint-down(lg) {
      padding-left: 15px;
      padding-right: 15px;
    }
    @include media-breakpoint-down(md) {
      padding: 40px 5px 140px;
    }

    .left-side p {
      @include px-to-rem(28);
      line-height: 42px;
      @include media-breakpoint-down(md) {
        @include px-to-rem(20);
        line-height: 26px;
      }
    }

    .right-side p {
      @include px-to-rem(22);
      line-height: 36px;
      @include media-breakpoint-down(md) {
        @include px-to-rem(18);
        line-height: 24px;
      }
    }
  }
}

/* our-partners
-------------------------------------------*/
.our-partners {
  margin-top: -110px;
  margin-bottom: 100px;
  padding-left: 0;
  padding-right: 0;

  .box-title h2 {
    @include media-breakpoint-down(md) {
      margin-bottom: 30px;
    }
  }


  .top-content {
    padding-left: 72px;
    padding-right: 72px;
    @include media-breakpoint-down(xl) {
      padding-left: 40px;
      padding-right: 40px;
    }
    @include media-breakpoint-down(lg) {
      padding-left: 15px;
      padding-right: 15px;
    }
    @include media-breakpoint-down(md) {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .box-title {
    color: $palcheColor;
  }

  .map-wrapper {
    background: #F5F5F7;

    form {
      padding: 20px 15px 5px;
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid #EAEAEA;
      margin-bottom: 20px;

      h5 {
        @include px-to-rem(20);
        font-weight: 600;
        line-height: 32px;
        text-transform: uppercase;
        margin-bottom: 5px;
        color: $palcheColor;
        width: 100%;
      }

      input {
        border: none;
        border-bottom: 1px solid var(--palche-color, #1D2A4C);
        background: none;
        height: 40px;
        color: #7D7E82;
        @include px-to-rem(16);
        font-weight: 400;
        line-height: 22px;
        min-width: 100%;
        width: 100%;
        margin-bottom: 0;
        box-shadow: none !important;
        padding: 0;
      }

      .form-row {
        width: calc(100% - 110px);
        padding-right: 15px;
      }

      .submit-btn {
        position: relative;
        z-index: 0;
        border: none;
        background: none;
        @include px-to-rem(14);
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
        padding: 12px 25px;
        color: $palcheColor;
        @include transition;

        &:before {
          content: "";
          width: 100%;
          height: 100%;
          transform: skewX(-15deg);
          background: #94d72a;
          z-index: -1;
          position: absolute;
          top: 0;
          left: 0;
        }

        &:hover {
          transform: scale(1.1);
        }
      }
    }

    .dealers-list {
      box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.15);

      ul {
        padding: 0;

        li {
          @include px-to-rem(16);
          margin-bottom: 30px;

          .num-dealers {
            width: 52px;
            height: 61px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: url(../images/icons/map-marker.png) no-repeat center center;

            color: #F5F5F7;
            font-family: 'DM Mono', monospace;
            @include px-to-rem(18);
            font-weight: 500;
            line-height: 26px;
            padding-bottom: 8px;
            margin-bottom: 9px;
          }

          .name {
            @include px-to-rem(22);
            font-weight: 600;
            line-height: 24px;
            margin-bottom: 9px;
            color: $palcheColor;
          }

          p {
            color: #7D7E82;
            line-height: 24px;
            margin-bottom: 9px;
          }

          a {
            color: #7D7E82;
            line-height: 24px;
          }
        }
      }
    }

    iframe {
      height: 750px;
      @include media-breakpoint-down(md) {
        height: 480px;
      }
    }
  }

  &.page {
    margin: 0;

    .top-content {
      padding-bottom: 26px;
    }

    .box-title {
      color: $white;
    }

    .map-wrapper {
      .dealers-list {
        @include media-breakpoint-down(md) {
          display: block;
        }
      }

      .map-box {
        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }
  }

  .dealers-right-side {
    text-align: right;
    @include media-breakpoint-down(lg) {
      text-align: left;
    }

    p {
      line-height: 24px;
    }
  }
}

/* our-video
-------------------------------------------*/
.our-video {
  padding: 120px 0;

  .swiper-wrapper {
    transition-timing-function: linear;
  }

  @include media-breakpoint-down(md) {
    padding: 120px 0 80px;
  }

  .item {
    position: relative;

    .img-video {
      width: 100%;
      height: 342px;
      position: relative;
      z-index: 0;

      &:before {
        content: "";
        background: rgba(6, 19, 56, 0.50);
        position: absolute;
        width: 100%;
        height: 100%;
      }

      @include media-breakpoint-down(lg) {
        height: 220px;
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: initial;
      }
    }

    .play-btn {
      width: 43px;
      height: 47px;
      background: url(../images/icons/play-video.svg) no-repeat center center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      border: none;
      box-shadow: none;
      padding: 0;

      &:hover {
        transform: translate(-50%, -50%) scale(1.2);
      }
    }

    .title {
      color: #F5F5F7;
      @include px-to-rem(24);
      font-size: 24px;
      line-height: 30px;
      position: absolute;
      bottom: 24px;
      left: 28px;
      max-width: calc(100% - 56px);
      @include media-breakpoint-down(lg) {
        @include px-to-rem(18);
        line-height: 22px;
        bottom: 15px;
        left: 20px;
      }
    }
  }
}

/* order-with-manager
-------------------------------------------*/
.order-with-manager {
  padding-bottom: 70px;

  form {
    max-width: 890px;

    input {
      margin-bottom: 50px;
    }

    textarea {
      margin-bottom: 45px;
    }
  }

  h5 {
    color: #F5F5F7;
    @include px-to-rem(20);
    font-weight: 600;
    line-height: 32px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  a {
    color: $white;
    margin-bottom: 10px;
    display: inline-flex;
    @include media-breakpoint-down(md) {
      margin-bottom: 0;
    }
  }

  .contacts-list {
    margin-bottom: 55px;
    @include media-breakpoint-down(md) {
      margin-bottom: 30px;
    }
  }

  p {
    color: #F5F5F7;
    @include px-to-rem(18);
    font-weight: 400;
    line-height: 28px;

    br {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }

  .social-networks {
    margin-top: 55px;
    display: flex;
    @include media-breakpoint-down(md) {
      margin-top: 30px;
      margin-bottom: 30px;
    }

    li {
      margin-right: 30px;
      display: inline-flex;
      align-items: center;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .map-box {
    margin-top: 40px;
  }

  .contacts-info {
    @include media-breakpoint-down(md) {
      padding-top: 50px;
    }
  }
}

/* scroll-up
-------------------------------------------*/
.scroll-up-box {
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
  position: relative;
  @include media-breakpoint-down(md) {
    padding-bottom: 0;
  }

  &.white-bg {
    background: #fff;
  }

  a, button {
    display: inline-flex;
    width: 53px;
    height: 28px;
    background: url(../images/icons/scroll-up.svg) no-repeat center center;
    border: none;
    margin-bottom: 0;
    @include media-breakpoint-down(md) {
      position: absolute;
      bottom: -70px;
      right: 25px;
    }

    &:hover {
      transform: scale(1.1);
    }
  }
}

/* all-news
---------------------------------------------*/
.all-news {
  margin-bottom: 70px;

  &.pt-110 {
    padding-top: 110px;
    @include media-breakpoint-down(md) {
      padding-top: 60px;
    }
  }

  @include media-breakpoint-down(md) {
    margin-bottom: 60px;
  }

  .news-item {
    margin-bottom: 70px;
    @include media-breakpoint-down(md) {
      margin-bottom: 40px;
    }
  }

  .inner-wrap {
    position: relative;
  }

  .img-box {
    margin-bottom: 16px;
    overflow: hidden;
    height: 340px;
    width: 100%;
    @include media-breakpoint-down(sm) {
      height: 270px;
    }

    img {
      max-width: 100%;
      width: 100%;
      margin-bottom: 0;
      @include transition;
      object-fit: cover;
      height: 100%;
    }
  }

  .title {
    color: $white;
    @include px-to-rem(27);
    font-weight: 600;
    line-height: 36px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-decoration: underline;
    text-decoration-color: $green;
    margin-bottom: 20px;
  }

  .description {
    @include px-to-rem(18);
    line-height: 26px;
    margin-bottom: 20px;

    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .date {
    @include px-to-rem(18);
    line-height: 26px;
  }

  .news-item {
    &:hover {
      .img-box img {
        transform: scale(1.1);
      }
    }
  }

  .read-more {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    font-size: 0;
  }

  .all-news-link {
    top: 20px;
    right: 0;
  }
}

/* page-title
---------------------------------------------*/
.page-title {
  position: relative;

  &.news {
    height: 810px;
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @include media-breakpoint-down(md) {
      height: auto;
      min-height: 315px;
    }

    .background-img {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .content {
      padding-bottom: 20px;
    }
  }

  .date {
    margin-bottom: 30px;
    @include px-to-rem(18);
    line-height: 26px;
  }

}

/* News details
---------------------------------------------*/
.news-details {
  background: #fff;
  @include media-breakpoint-down(md) {
    background: #fff;
  }

  color: $palcheColor;
  padding-top: 60px;
  padding-bottom: 70px;
  @include media-breakpoint-down(md) {
    padding-top: 30px;
    padding-bottom: 45px;
  }

  h6 {
    @include px-to-rem(28);
    line-height: 42px;
    margin-bottom: 50px;
    @include media-breakpoint-down(md) {
      @include px-to-rem(20);
      line-height: 26px;
      margin-bottom: 20px;
    }
  }

  p,
  ul {
    @include px-to-rem(20);
    line-height: 28px;
    margin-bottom: 50px;
    @include media-breakpoint-down(md) {
      @include px-to-rem(18);
      line-height: 24px;
      margin-bottom: 20px;
    }
  }

  h3 {
    @include px-to-rem(40);
    margin-bottom: 50px;
    @include media-breakpoint-down(md) {
      @include px-to-rem(27);
      margin-bottom: 20px;
    }
  }

  .video-popup {
    height: 606px;
    position: relative;
    z-index: 0;
    margin-bottom: 20px;

    @include media-breakpoint-down(lg) {
      height: 400px;
    }
    @include media-breakpoint-down(md) {
      height: 210px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      margin-bottom: 0;
    }

    .title {
      position: absolute;
      bottom: 30px;
      left: 30px;
      z-index: 1;
      @include px-to-rem(20);
      line-height: 24px;
      color: $white;
      @include media-breakpoint-down(md) {
        @include px-to-rem(18);
        line-height: 22px;
        bottom: 15px;
        left: 20px;
      }
    }

    .play-btn {
      width: 43px;
      height: 47px;
      background: url(../images/icons/play-video.svg) no-repeat center center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      border: none;
      box-shadow: none;
      padding: 0;
    }
  }

  .date {
    color: $gray;
    @include px-to-rem(16);
    line-height: 24px;
  }

  .article {
    a {
      color: $palcheColor;
      font-weight: bold;

      &:hover {
        color: $green;
      }
    }
  }
}

/* video-section
---------------------------------------------*/
.video-section {
  margin-bottom: 100px;

  .secondary-menu {
    display: flex;
    margin-bottom: 40px;

    li {
      margin-right: 10px;
    }

    a {
      color: $white;
      position: relative;
      padding: 10px 20px;
      background: none;
      @include px-to-rem(14);
      font-weight: 600;
      text-transform: uppercase;
      display: inline-flex;
      z-index: 0;
      @include media-breakpoint-down(lg) {
        padding: 10px 15px;
        @include px-to-rem(12);
      }

      &:before {
        content: '';
        width: 100%;
        height: 100%;
        transform: skewX(-15deg);
        background: $green;
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        @include transition;
      }

      &.active,
      &:hover {
        color: $palcheColor;

        &:before {
          opacity: 1;
        }
      }
    }
  }

  .filter-menu {
    label {
      color: $white;
      font-weight: 600;
      line-height: 38px;
      margin-bottom: 27px;
      @include media-breakpoint-down(lg) {
        margin-bottom: 17px;
      }
    }

    ul {
      @include media-breakpoint-down(lg) {
        position: sticky;
        top: 80px;
      }

      li {
        margin-bottom: 15px;
        @include media-breakpoint-down(lg) {
          margin-bottom: 10px;
        }

        a {
          padding: 15px 18px;
          color: $white;
          @include px-to-rem(18);
          font-weight: 600;
          line-height: 20px;
          display: inline-flex;
          @include media-breakpoint-down(lg) {
            padding: 10px 13px;
            @include px-to-rem(16);
          }

          &:hover,
          &.active {
            background: rgba(240, 241, 238, 0.32);
          }
        }
      }
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    padding-top: 72px;
    @include media-breakpoint-down(lg) {
      padding-top: 53px;
    }
  }

  .left-side {
    width: 40%;
    position: relative;
    z-index: 0;
    @include media-breakpoint-down(xxl) {
      width: 100%;
      margin-bottom: 32px;
      text-align: center;
    }

    .product-title {
      @include px-to-rem(29);
      line-height: 38px;
      text-transform: uppercase;
      position: relative;
      z-index: 0;
      max-width: 335px;
      margin: 0 0 40px 68px;
      font-weight: 400;
      @include media-breakpoint-down(xxl) {
        margin: 0 auto 40px;
      }

      &:before {
        content: '';
        width: 22px;
        height: 62px;
        background: url(../images/bg/bg-title.svg) no-repeat top left;
        z-index: -1;
        position: absolute;
        bottom: -30px;
        left: -19px;
        @include media-breakpoint-down(xxl) {
          display: none;
        }
      }
    }

    .img-box {
      margin-bottom: 67px;
      margin-right: 17%;
      text-align: center;

      &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: url(../images/bg/bg-products.webp) no-repeat center center;
        background-size: contain;
        z-index: -1;
        opacity: 0.4;
        @include media-breakpoint-down(xxl) {
          top: 40px;
        }
      }

      @include media-breakpoint-down(xxl) {
        margin-right: 0;
      }
    }

    .more-details {
      position: relative;
      padding-left: 19%;
      @include media-breakpoint-down(xxl) {
        padding-left: 0;
      }

      &:after {
        content: '';
        width: 115px;
        height: 10px;
        background: url(../images/icons/more-arrow.svg) no-repeat left center;
        display: inline-block;
        margin-left: 10px;
      }
    }
  }

  .right-side {
    width: 60%;
    @include media-breakpoint-down(xxl) {
      width: 100%;
    }
  }

  .product-carousel {
    .img-box {
      position: relative;

      &:before {
        content: "";
        background: rgba(6, 19, 56, 0.50);
        position: absolute;
        width: 100%;
        height: 100%;
      }

      .play-btn {
        width: 43px;
        height: 47px;
        background: url(../images/icons/play-video.svg) no-repeat center center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        border: none;
        box-shadow: none;
        padding: 0;

        &:hover {
          transform: translate(-50%, -50%) scale(1.2);
        }
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      z-index: 2;
      width: 41px;
      height: 30px;
      position: absolute;
      top: calc(50% - 30px);
      transform: translateY(-50%);
      background: url(../images/icons/arrow-left.svg) no-repeat center center;

      &:after {
        display: none;
      }
    }

    .swiper-button-prev {
      left: 15px;
    }

    .swiper-button-next {
      right: 15px;
      background: url(../images/icons/arrow-right.svg) no-repeat center center;
    }

    .swiper-pagination {
      z-index: 2;
      position: static;
      margin-top: 30px;

      .swiper-pagination-bullet {
        background: #616A80;
        margin-left: 10px;
        margin-right: 10px;

        &.swiper-pagination-bullet-active {
          width: 15px;
          height: 15px;
          background: $green;
          transform: translateY(3px);
        }
      }
    }
  }
}

/* product-menu
---------------------------------------------*/
.product-menu {
  min-width: 100px;
  z-index: 2;

  .nav-item.dropdown {
    position: relative;

    .dropdown-menu {
      position: absolute;
      top: 0;
      left: 100%;
      background: transparent;
      border: none;
      padding: 0;
      --bs-dropdown-min-width: 1rem;
      width: 306px;

      .dropdown-menu-inner {
        position: relative;
        background: $palcheColor;
        display: flex;
        flex-wrap: wrap;
        border-radius: 0 10px 10px 0;

        @include media-breakpoint-down(md) {
          .dropdown-item {
            padding: 1em 0.4em;

            .menu-icon {
              margin: 0 auto;
            }

            .label {
              display: none;
            }
          }
        }
      }

      &.show {
        display: flex;
        z-index: 1;
      }

      > div {
        float: left;
      }

      .dropdown-item {
        background: transparent !important;
      }
    }
  }

  .dropdown-item,
  .nav-link {
    color: white !important;
    padding: 1em;
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 0;
    background: $palcheColor;

    @include media-breakpoint-down(md) {
      max-width: 100px;
      padding: 1em 0.5em 0;

      .text-break {
        display: none;
      }
    }

    .menu-icon {
      width: 70px;
      height: 70px;
      display: block;
      border-radius: 50%;
      border: 2px solid #fff;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 30px;
      background-color: transparent;
      margin: 0 auto 10px;
      @include transition;
      position: relative;

      img {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &.hover {
          opacity: 0;
        }
      }
    }

    &:hover,
    &.active {
      .menu-icon {
        background-color: $green;
        border-color: transparent;

        img {
          &.start {
            opacity: 0;
          }

          &.hover {
            opacity: 1;
          }
        }
      }
    }
  }

  .dropdown-toggle::after {
    width: 5.704px;
    height: 16px;
    border: none;
    position: absolute;
    top: 42px;
    right: 0;
    background: url(../images/icons/menu-right-arrow.svg) no-repeat center center;
  }
}

/* product-list
----------------------------------------------*/
.product-list {
  .item {
    display: flex;
    height: auto;
    margin-bottom: 30px;

    .inner-wrap {
      background: rgba(217, 217, 217, 1);
      height: 100%;
      width: 100%;
      margin: 0 15px;
      position: relative;
      z-index: 0;
    }

    .read-more {
      font-size: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;

      &:hover {
        transform: scale(1.1);
      }
    }

    .top-content {
      position: relative;
      padding: 10px;

      .label {
        @include px-to-rem(14);
        font-style: normal;
        font-weight: 600;
        line-height: 15px;
        text-transform: uppercase;
        position: absolute;
        top: 20px;
        left: -12px;
        height: auto;
        display: inline-flex;
        align-items: center;
        z-index: 0;
        color: $palcheColor;
        padding: 6px 16px 6px 12px;
        max-width: 90%;

        .bg-layout-1 {
          content: '';
          width: 80%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 20%;
          z-index: -1;

          transform: skewX(-15deg);
          background: #94d72a;
        }

        .bg-layout-2 {
          content: '';
          width: 30%;
          height: 100%;
          background: #94d72a;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -2;
        }

        .bg-layout-3 {
          width: 12px;
          height: 100%;
          transform: skewY(35deg);
          background: #69ab00;
          position: absolute;
          top: 5px;
          left: 0;
          z-index: -3;

          &:before {
            content: "";
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.2);
            display: block;
          }
        }
      }

      .type-of-drive {
        position: absolute;
        left: 14px;
        bottom: 6px;
        width: 33px;
        height: 33px;

        &.motor-type {
          background: url(../images/icons/diesel-blue.svg) no-repeat left top;
        }

        &.fuel-type {
          background: url(../images/icons/gasoline-blue.svg) no-repeat left top;
        }

        &.electric-type {
          background: url(../images/icons/electric-blue.svg) no-repeat left top;
        }
      }

      .img-box {
        height: 16vw;
        max-height: 295px;
        width: 100%;
        position: relative;

        @include media-breakpoint-down(lg) {
          min-height: 170px;
        }
        @include media-breakpoint-down(md) {
          min-height: 270px;
        }

        img {
          object-fit: contain;
          width: 100%;
          height: 100%;
          @include transition;
        }

        img:nth-child(2) {
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
        }
      }
    }

    &:hover {
      .img-box.with-hover {
        img:nth-child(1) {
          opacity: 0;
        }

        img:nth-child(2) {
          opacity: 1;
        }
      }
    }

    .bottom-content {
      display: flex;
      padding-bottom: calc(var(--bs-gutter-x) * .6);
      margin-right: calc(var(--bs-gutter-x) * .3);
      margin-left: calc(var(--bs-gutter-x) * .3);
    }

    .title-box {
      width: 60%;
      padding-right: calc(var(--bs-gutter-x) * .3);
      padding-left: calc(var(--bs-gutter-x) * .3);
      @include px-to-rem(16);
      font-weight: 400;
      line-height: 20px;
      text-transform: uppercase;
      color: $palcheColor;

      a {
        @include px-to-rem(16);
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        text-transform: uppercase;
        color: $palcheColor;
      }
    }

    .price-box {
      width: 40%;
      padding-right: calc(var(--bs-gutter-x) * .3);
      padding-left: calc(var(--bs-gutter-x) * .3);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;

      .price {
        @include px-to-rem(16);
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        color: $palcheColor;

        &.red-price {
          color: $red;
        }
      }

      .old-price {
        color: #ADADAD;
        @include px-to-rem(14);
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
        text-decoration: line-through;
        margin-bottom: 5px;
      }
    }
  }
}

/* product-detail-section
----------------------------------------------*/
.product-detail-section {
  .product-left {
    display: flex;

    @include media-breakpoint-down(lg) {
      margin-bottom: 60px;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 0;
    }

    .product-thumbnails {
      width: 110px;

      @include media-breakpoint-down(sm) {
        width: 100%;
      }

      @include media-breakpoint-down(sm) {
        order: 2;
      }
    }

    .wrap-3d-reverse {
      width: calc(100% - 110px);
      padding: 0 30px;


      @include media-breakpoint-down(sm) {
        width: 100%;
        order: 1;
        padding: 0;
      }

      .inner-wrap {
        position: relative;
        max-width: 80%;
        margin: 0 auto;

        @include media-breakpoint-down(sm) {
          max-width: 100%;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }

  .video-links {
    a, button {
      width: 100px;
      height: 80px;
      display: flex;
      align-items: flex-end;
      background: #DF483D url(../images/icons/play-video-white.svg) center 20px no-repeat;
      background-size: 25px;
      justify-content: center;
      position: relative;
      color: $white;
      @include px-to-rem(11);
      font-weight: 600;
      line-height: 22px;
      text-transform: uppercase;
      margin-bottom: 10px;
      padding-bottom: 5px;

      &:hover {
        width: 110px;
        height: 90px;
        background-size: 30px;
      }
    }

    button {
      border: none;
      margin: 5px 0 5px 0;
      padding: 0 0 5px 0;

      :hover {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .wrap-3d-reverse {
    position: relative;
    z-index: 0;

    .inner-wrap:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: url(../images/bg/bg-products.webp) center center no-repeat;
      z-index: -1;
      background-size: contain;
      opacity: 0.2;
    }

    .navs {
      max-width: 70%;
      margin: 0 auto;
      text-align: center;
      color: #636F8F;
      font-size: 27px;
      text-transform: uppercase;
      padding-top: 40px;
      background: url(../images/icons/arrow360-left.svg) no-repeat left top, url(../images/icons/arrow360-right.svg) no-repeat right top;
      background-size: 25%;
    }
  }

  .product-title {
    color: #F5F5F7;
    @include px-to-rem(60);
    font-weight: 600;
    line-height: 66px;
    text-transform: uppercase;
    margin-bottom: 35px;

    @include media-breakpoint-down(sm) {
      @include px-to-rem(40);
      line-height: 46px;
    }
  }

  .art-no {
    color: #636F8F;
    @include px-to-rem(20);
    line-height: 24px;
    text-transform: uppercase;
    margin-bottom: 35px;
  }

  .specification-sheet {
    display: flex;
    margin-bottom: 35px;

    .sp-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      @include px-to-rem(18);
      font-weight: 600;
      line-height: 24px;
      margin-right: 60px;

      &:last-of-type {
        margin-right: 0;
      }

      .icon-box {
        height: 53px;
        width: 53px;
        margin-bottom: 10px;

        img {
          width: 100%;
          height: 100%;
          margin-bottom: 0;
        }
      }
    }
  }

  .short-description {
    @include px-to-rem(18);
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 35px;
  }

  .product-status {
    color: #94D72A;
    @include px-to-rem(18);
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 35px;
  }

  .product-price {
    @include px-to-rem(40);
    font-weight: 600;
    line-height: 66px;
    margin-bottom: 35px;
    text-transform: uppercase;
  }

  .wrap-button {
    margin-bottom: 90px;
    @include media-breakpoint-down(lg) {
      margin-bottom: 40px;
    }

    .buy-btn {
      @include px-to-rem(18);
      padding-left: 55px;
      padding-right: 35px;
      width: auto;

      &:after {
        content: '';
        background: url(../images/icons/cart-blue.svg) no-repeat center;
        width: 18px;
        height: 18px;
        position: absolute;
        top: 50%;
        left: 30px;
        z-index: -1;
        transform: translateY(-50%);
      }
    }
  }

  .see-all-reviews {
    color: $white;
    margin-bottom: 20px;
    display: inline-flex;
  }

  .accordion {
    border-color: rgba(245, 245, 247, 0.50);

    .accordion-item {
      border-color: rgba(245, 245, 247, 0.50);
    }

    .accordion-body {
      padding-left: 35px;
      padding-right: 35px;
      padding-bottom: 0;
      border-top: 1px solid rgba(245, 245, 247, 0.50);
      color: $white;
      @include px-to-rem(16);
      font-weight: 400;
      line-height: 26px;
      @include media-breakpoint-down(md) {
        padding-left: 0;
        padding-right: 0;
      }

      p:first-of-type {
        margin-top: 20px;
      }

      b, strong {
        font-weight: 600;
      }
    }
  }

  .specifications-list {
    li {
      display: flex;
      @include px-to-rem(16);
      font-weight: 400;
      line-height: 26px;
      padding-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid rgba(245, 245, 247, 0.50);

      &:last-of-type {
        border: none;
      }

      .sp-name {
        width: 60%;
      }

      .sp-performance {
        width: 40%;
        text-align: center;
      }
    }
  }

}

//
.reviews-list {
  padding-top: 20px;
  padding-bottom: 20px;

  .reviews-row {
    border-bottom: 1px solid rgba(245, 245, 247, 0.50);
    margin-bottom: 20px;

    &:last-of-type {
      // border: none;
      margin-bottom: 0;
    }
  }

  .star-status {
    width: 114px;
    height: 22px;
    background: url(../images/icons/star-dark.svg) repeat-x left center;
    background-repeat: space;
    position: relative;
    z-index: 0;
    margin-bottom: 10px;

    &:after {
      content: '';
      height: 100%;
      width: 0;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      background: url(../images/icons/star-green.svg) space left center;
    }

    &.star-5:after {
      width: 100%;
    }

    &.star-4:after {
      width: 80%;
    }

    &.star-3:after {
      width: 60%;
    }

    &.star-2:after {
      width: 40%;
    }

    &.star-1:after {
      width: 20%;
    }
  }

  .review-body {
    margin-bottom: 10px;
  }

  .bottom-line {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .user-name {
      font-weight: 600;
    }

    .date {
      color: #636F8F;
    }
  }
}

.see-more-reviews {
  background: transparent;
  border: none;
  color: $white;
  display: inline-flex;
  align-items: center;

  img {
    margin-left: 10px;
    margin-bottom: 0;
    margin-top: 4px;
  }

  @include media-breakpoint-down(md) {
    margin-bottom: 60px;
  }
}

/* order-processing
---------------------------------------------*/
.order-processing {
  background: #fff;
  color: $palcheColor;
  padding: 33px 50px;
  margin-bottom: 20px;
  @include media-breakpoint-down(md) {
    padding: 33px 15px;
  }

  .row {
    margin-bottom: 15px;
  }

  h5 {
    @include px-to-rem(27);
    font-weight: 600;
    line-height: 36px;
    text-transform: uppercase;
    position: relative;
    margin-bottom: 30px;

    &:before {
      content: '';
      background: url(../images/icons/stripes-title.svg) no-repeat center left;
      background-size: contain;
      width: 36px;
      height: 18px;
      position: absolute;
      top: 11px;
      left: -50px;
    }
  }

  label {
    color: #1D2A4C;
    @include px-to-rem(16);
    margin-bottom: 4px;
  }

  input {
    margin-bottom: 50px;
    @include media-breakpoint-down(md) {
      margin-bottom: 30px;
    }
  }

  .delivery-row {
    padding: 22px 0;
  }

  .delivery-info {
    display: none;
    margin-bottom: 0;

    &:before {
      content: '';
      width: calc(100% + 46px);
      height: calc(100% + 46px);
      border: 2px solid #E7E9ED;
      position: absolute;
      top: -23px;
      left: -23px;
      z-index: -1;
    }
  }

  .wrap-radiobox {
    input[type="radio"]:checked + label + .delivery-info {
      display: flex;
    }
  }

  .form-select,
  input,
  textarea {
    border-bottom: 2px solid #A7ADBC;
  }
}

/* wrap-your-order-list
---------------------------------------------*/
.wrap-your-order-list {
  margin-left: 30px;
  background: #fff;
  padding: 38px 50px 18px;
  margin-bottom: 30px;
  @include media-breakpoint-down(xl) {
    margin-left: 0;
  }
  @include media-breakpoint-down(md) {
    padding: 33px 15px 18px;
    margin-bottom: 20px;
  }

  h6 {
    color: $palcheColor;
    @include px-to-rem(20);
    font-weight: 600;
    line-height: 32px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  .product-item {
    position: relative;
  }

  .product-cart-list {
    border-top: 1px solid #E7E9ED;
    padding-top: 20px;
    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
    }

    .img-box {
      @include media-breakpoint-down(md) {
        flex: 0 0 80px;
        width: 80px;
        height: 80px;
      }
    }
  }

  .product-info {
    @include media-breakpoint-down(md) {
      width: calc(100% - 95px);
      .remove-product {
        position: absolute;
        bottom: 30px;
        right: 16px;
        font-size: 0;
      }
    }
  }

  .product-total {
    @include media-breakpoint-down(md) {
      display: block;
      height: auto;
      padding-left: 95px;
      padding-top: 10px;
      .cart-counter {
        display: inline-flex;
        margin-top: 15px;
      }
    }
  }

  .total-sum {
    color: $palcheColor;
    padding-bottom: 24px;
    border-bottom: 1px solid #E7E9ED;

    .sum {
      text-align: right;
    }

    &.pay-off {
      font-weight: 600;
      padding-top: 15px;
      border: none;
    }

    .promo-code-errors {
      font-weight: normal;
      margin-bottom: 50px;
      @include media-breakpoint-down(md) {
        margin-bottom: 30px;
      }
    }
  }

  .d-flex.justify-content-end {
    @include media-breakpoint-down(md) {
      justify-content: center !important;
    }
  }
}

.divider {
  height: 1px;
  border-bottom: 1px solid #E7E9ED;
  margin-top: 10px;
  margin-bottom: 20px;
}

/*
---------------------------------------------*/
.wrap-also-buy {
  margin-left: 30px;
  background: #fff;
  padding: 38px 50px 18px;
  margin-bottom: 30px;
  @include media-breakpoint-down(xl) {
    margin-left: 0;
  }
  @include media-breakpoint-down(md) {
    padding: 38px 15px 18px;
    margin-bottom: 20px;
  }

  h6 {
    color: $palcheColor;
    @include px-to-rem(20);
    font-weight: 600;
    line-height: 32px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  .product-list {
    border-top: 1px solid #E7E9ED;
    padding-bottom: 0;

    .item {
      padding-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #E7E9ED;
      flex-wrap: wrap;

      &:last-of-type {
        @include media-breakpoint-down(md) {
          margin-bottom: 10px;
        }
      }
    }
  }

  .img-box {
    width: 110px;
    height: 110px;
    margin-right: 20px;
    @include media-breakpoint-down(md) {
      width: 80px;
      height: 80px;
      margin-right: 15px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      opacity: 1 !important;
    }
  }

  .content {
    width: calc(100% - 260px);
    color: $palcheColor;
    @include media-breakpoint-down(md) {
      width: calc(100% - 95px);
    }
  }

  .product-name {
    color: $palcheColor;
    @include px-to-rem(18);
    font-weight: 400;
    line-height: 24px;
    text-transform: uppercase;
  }

  .price {
    margin-top: 15px;
    font-weight: 600;
  }

  .wrap-button {
    width: 115px;
    display: flex;
    align-items: center;
    margin-left: 15px;
    @include media-breakpoint-down(md) {
      width: 100%;
      padding-left: 95px;
      margin-left: 0;
      padding-top: 10px;
      .green-btn {
        margin-bottom: 0;
      }
    }
  }
}

/* our-manager-contacts
-----------------------------------------------------------*/
.our-manager-contacts {
  margin-bottom: 40px;

  .manager-list {
    .custom-col {
      flex: 0 0 20%;
      width: 20%;
      padding-right: calc(var(--bs-gutter-x) * .5);
      padding-left: calc(var(--bs-gutter-x) * .5);
      margin-bottom: 40px;
      @include media-breakpoint-down(xl) {
        flex: 0 0 25%;
        width: 25%;
      }
      @include media-breakpoint-down(lg) {
        flex: 0 0 33.33333%;
        width: 33.33333%;
      }
      @include media-breakpoint-down(md) {
        flex: 0 0 50%;
        width: 50%;
      }
      @include media-breakpoint-down(sm) {
        flex: 0 0 100%;
        width: 100%;
      }

    }
  }

  .img-box {
    margin-bottom: 20px;

    img {
      width: 100%;
      height: auto;
    }
  }

  .name {
    @include px-to-rem(20);
    font-weight: 600;
    line-height: 32px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .status {
    @include px-to-rem(18);
    font-weight: 400;
    line-height: 28px; /* 155.556% */
    margin-bottom: 10px;
  }

  .phone,
  .mail {
    display: inline-block;
    @include px-to-rem(18);
    font-weight: 400;
    line-height: 28px; /* 155.556% */
    margin-bottom: 10px;
    color: $white;

    &:hover {
      color: $green;
    }
  }
}

/* page-content text
----------------------------------------------------------*/
.page-content {
  @include px-to-rem(20);
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: $palcheColor;
  @include media-breakpoint-down(md) {
    @include px-to-rem(18);
    line-height: 24px;
  }

  h4 {
    @include px-to-rem(22);
    font-weight: 600;
    line-height: 42px;
    text-transform: uppercase;
    margin-bottom: 1rem;
    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
    }
  }

  .big-text {
    @include px-to-rem(28);
    font-weight: 400;
    margin-bottom: 50px;
    color: #000;
    line-height: 42px;
    @include media-breakpoint-down(md) {
      @include px-to-rem(20);
      line-height: 26px;
      margin-bottom: 20px;
    }
  }

  ul {
    padding: 0;
    margin-bottom: 50px;
    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
    }

    li {
      margin-bottom: 10px;
    }
  }

  p {
    line-height: 28px;
    margin-bottom: 1rem;
    @include media-breakpoint-down(md) {
      line-height: 24px;
      margin-bottom: 20px;
    }
  }
}

//
.page-height {
  min-height: calc(100vh - 385px);
}

//
.box-404 {
  font-size: 433px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 56.29px;
  text-transform: uppercase;
  text-align: center;
  color: white;
  max-width: 1150px;
  margin: 0 auto 50px;
  position: relative;
  z-index: 0;
  @include media-breakpoint-down(xl) {
    font-size: 234px;
    max-width: 570px;
    letter-spacing: inherit;
  }
  @include media-breakpoint-down(md) {
    font-size: 140px;
    max-width: 300px;
    letter-spacing: inherit;
    margin-bottom: 30px;
  }

  &:before {
    content: '';
    height: 85%;
    width: 33.3333%;
    position: absolute;
    bottom: 0;
    left: 46%;
    transform: translateX(-50%);
    background: url(../images/content/big-product-1.png) no-repeat center center;
    background-size: contain;
  }

  span {
    color: #c1c1c1;
  }
}

p.p-404 {
  max-width: 810px;
  margin: 0 auto 150px;
  color: white !important;
  @include media-breakpoint-down(md) {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 20px;
    max-width: 70vw;
  }

  a {
    color: white;
    text-decoration: underline;
    text-decoration-color: $green;

    &:hover {
      text-decoration-color: $white;
    }
  }
}

/* user-cabinet-tabs
-------------------------------------------------------*/
.user-cabinet-tabs {
  @media(max-width: 1120px) {
    display: block !important;
  }

  .nav {
    @media(max-width: 1120px) {
      display: flex;
      flex-direction: inherit !important;
      .tabs-btn {
        margin-right: 15px;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
    @include media-breakpoint-down(md) {
      justify-content: space-between;
      margin-right: 0 !important;
    }
  }

  .tab-content {
    width: 100%;
    position: relative;
    padding-left: 16px;
    @media(max-width: 1120px) {
      padding-left: 0;
    }

    &:before {
      content: '';
      width: 1px;
      height: calc(100% + 15px);
      background: #E7E9ED;
      position: absolute;
      top: -20px;
      left: 0;
      @media(max-width: 1120px) {
        display: none;
      }
    }
  }
}

/* products-history-list
-------------------------------------------------------*/
.products-history-list {
  .item {
    background: white;
    padding: 15px;
    margin-bottom: 5px;
    display: flex;
    flex-wrap: wrap;
    @include media-breakpoint-down(md) {
      margin-bottom: 4px;
      display: block;
      position: relative;
    }

    &.table-header {
      @include media-breakpoint-down(md) {
        display: none;
      }

      label {
        @include px-to-rem(12);
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.24px;
        text-transform: uppercase;
        color: #8F95A6;
      }

      .product-info {
        @media(max-width: 1560px) {
          padding-bottom: 0 !important;
        }
      }

      .cost {
        @media(max-width: 1560px) {
          display: none !important;
        }
      }
    }

    button {
      margin-bottom: 0;
    }

    .product-info {
      flex: 0 0 calc(100% - 555px);
      width: calc(100% - 555px);
      display: flex;
      @media(max-width: 1640px) {
        flex: 0 0 calc(100% - 495px);
        width: calc(100% - 495px);
      }
      @media(max-width: 1560px) {
        flex-wrap: wrap;
        position: relative;
        padding-bottom: 40px;
        display: block;
      }
      @media(max-width: 1360px) {
        flex: 0 0 calc(100% - 385px);
        width: calc(100% - 385px);
      }
      @include media-breakpoint-down(md) {
        width: 100%;
        overflow: hidden;
        position: static;
        padding-bottom: 20px;
      }

      .inner-info {
        flex: 0 0 calc(100% - 210px);
        width: calc(100% - 210px);
        display: flex;
        padding-left: 25px;
        align-items: center;
        @media(max-width: 1640px) {
          flex: 0 0 calc(100% - 190px);
          width: calc(100% - 190px);
        }
        @media(max-width: 1560px) {
          flex: 0 0 100%;
          width: 100%;
          display: block;
        }
        @media(max-width: 1360px) {
          padding-left: 0;
        }

        .img-box {
          flex: 0 0 90px;
          width: 90px;
          height: 90px;
          background: rgba(217, 217, 217, 1);
          margin-right: 20px;
          @media(max-width: 1560px) {
            float: left;
          }

          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }

        .content {
          flex: 0 0 calc(100% - 110px);
          width: calc(100% - 110px);
          @media(max-width: 1560px) {
            float: left;
            width: calc(100% - 115px);
          }

        }

        .name {
          @include px-to-rem(20);
          font-weight: 400;
          line-height: 20px;
          text-transform: uppercase;
          color: $palcheColor;
          display: inline-block;
          margin-bottom: 12px;
          @media(max-width: 1360px) {
            @include px-to-rem(14);
          }

          &:hover {
            color: $green;
          }
        }

        .art-no {
          color: #8F95A6;
          @include px-to-rem(15);
          line-height: 20px;
          letter-spacing: 0.3px;
          margin-bottom: 13px;
        }

        .reviews-btn {
          padding: 4px 18px;
          border-radius: 2px;
          border: 1px solid var(--palche-color, #1D2A4C);
          font-size: 12px;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 0.24px;
          text-transform: uppercase;

          &:hover {
            transform: scale(1.05);
          }

          @media(max-width: 1560px) {
            position: absolute;
            bottom: 0;
            left: 135px;
          }
          @media(max-width: 1360px) {
            left: 110px;
          }
          @include media-breakpoint-down(md) {
            left: 15px;
            bottom: 15px;
          }
        }
      }

      .cost {
        flex: 0 0 210px;
        width: 210px;
        @include px-to-rem(18);
        font-weight: 600;
        line-height: 20px;
        display: flex;
        align-items: center;
        @media(max-width: 1640px) {
          flex: 0 0 190px;
          width: 190px;
        }
        @media(max-width: 1560px) {
          width: 100%;
          float: none;
          padding-left: 135px ;
        }
      }
    }

    .files {
      flex: 0 0 185px;
      width: 185px;
      @media(max-width: 1640px) {
        flex: 0 0 165px;
        width: 165px;
      }
      @media(max-width: 1360px) {
        flex: 0 0 125px;
        width: 125px;
      }
      @include media-breakpoint-down(md) {
        width: auto;
        margin-bottom: 20px;
      }

      .download-file, a {
        @include px-to-rem(14);
        font-weight: 600;
        line-height: 20px;
        display: block;
        margin-bottom: 10px;
        background: url(../images/icons/bg-download-file.svg) center right no-repeat;
        padding-right: 20px;
        width: 100%;

        &:hover {
          color: $green;
        }
      }
    }

    .status {
      flex: 0 0 180px;
      width: 180px;
      @media(max-width: 1640px) {
        flex: 0 0 160px;
        width: 160px;
      }
      @media(max-width: 1360px) {
        flex: 0 0 110px;
        width: 110px;
      }
      @include media-breakpoint-down(md) {
        width: auto;
        margin-bottom: 20px;
      }

      .status-name {
        @include px-to-rem(14);
        font-weight: 600;
        line-height: 20px;
        display: inline-flex;
        align-items: center;
        padding-left: 20px;
      }

      .purchased {
        background: url(../images/icons/purchased.svg) no-repeat left center;
        color: rgba(115, 164, 38, 1);
      }

      .in-processing {
        background: url(../images/icons/in-processing.svg) no-repeat left center;
        color: rgba(240, 184, 28, 1);
      }

      .rejected {
        background: url(../images/icons/rejected.svg) no-repeat left center;
        color: rgba(243, 132, 133, 1);
      }
    }

    .service {
      flex: 0 0 190px;
      width: 190px;
      @media(max-width: 1640px) {
        flex: 0 0 170px;
        width: 170px;
      }
      @media(max-width: 1360px) {
        flex: 0 0 130px;
        width: 130px;
        padding-right: 0 !important;
      }
      @include media-breakpoint-down(md) {
        width: 100%;
        justify-content: flex-end;
        min-height: 30px;
      }

      .order-btn {
        padding: 4px 18px;
        border-radius: 2px;
        background: var(--green, #94D72A);
        display: inline-flex;
        align-items: center;
        @include px-to-rem(12);
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.24px;
        text-transform: uppercase;
        height: 30px;

        &:hover {
          transform: scale(1.05);
        }
      }
    }

    .service,
    .status,
    .files,
    .cost {
      padding-left: 40px;
      padding-right: 40px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      @media(max-width: 1640px) {
        padding-left: 20px;
        padding-right: 20px;
      }
      @media(max-width: 1360px) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}

/*
-------------------------------------------------------------*/
.product-service-list {
  .item {
    background: white;
    padding: 15px;
    margin-bottom: 5px;
    display: flex;
    flex-wrap: wrap;
    @include media-breakpoint-down(md) {
      margin-bottom: 4px;
      display: block;
      position: relative;
    }

    &.table-header {
      @media(max-width: 1360px) {
        display: none;
      }

      label {
        color: #8F95A6;
        @include px-to-rem(12);
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.24px;
        text-transform: uppercase;
      }
    }

    .product-info {
      flex: 0 0 calc(50% - 150px);
      width: calc(50% - 150px);
      display: flex;
      padding-left: 20px;
      padding-right: 25px;
      align-items: center;
      @media(max-width: 1640px) {
        flex: 0 0 calc(50% - 120px);
        width: calc(50% - 120px);
      }
      @media(max-width: 1360px) {
        flex: 0 0 50%;
        width: 50%;
        padding-left: 0;
        padding-right: 10px;
      }
      @include media-breakpoint-down(md) {
        width: 100%;
        overflow: hidden;
        position: static;
        padding-bottom: 20px;
      }

      .img-box {
        flex: 0 0 90px;
        width: 90px;
        height: 90px;
        background: rgba(217, 217, 217, 1);
        margin-right: 20px;
        @media(max-width: 1560px) {
          float: left;
        }
        @include media-breakpoint-down(md) {
          flex: 0 0 80px;
          width: 80px;
          height: 80px;
        }

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      .content {
        flex: 0 0 calc(100% - 110px);
        width: calc(100% - 110px);
        @media(max-width: 1560px) {
          float: left;
          width: calc(100% - 115px);
        }
        @include media-breakpoint-down(md) {
          float: left;
          width: calc(100% - 100px);
        }
      }

      .name {
        @include px-to-rem(20);
        font-weight: 400;
        line-height: 20px;
        text-transform: uppercase;
        color: $palcheColor;
        display: inline-block;
        margin-bottom: 12px;
        @media(max-width: 1640px) {
          @include px-to-rem(16);
        }
        @media(max-width: 1360px) {
          @include px-to-rem(14);
          margin-bottom: 6px;
        }

        &:hover {
          color: $green;
        }
      }

      .art-no {
        color: #8F95A6;
        @include px-to-rem(15);
        line-height: 20px;
        letter-spacing: 0.3px;
        @media(max-width: 1360px) {
          margin-bottom: 5px;
        }
      }

      .mobile-date {
        display: none;
        @include px-to-rem(18);
        line-height: 20px;
        @media(max-width: 1360px) {
          display: block;
        }
      }
    }

    .date,
    .status,
    .comment {
      padding-left: 25px;
      padding-right: 25px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      @media(max-width: 1640px) {
        padding-left: 20px;
        padding-right: 20px;
      }
      @media(max-width: 1360px) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    .date {
      flex: 0 0 150px;
      width: 150px;

      @include px-to-rem(18);
      font-weight: 400;
      line-height: 20px;

      @media(max-width: 1640px) {
        flex: 0 0 120px;
        width: 120px;
        @include px-to-rem(14);
      }
      @media(max-width: 1360px) {
        display: none;
      }
      @include media-breakpoint-down(md) {
        width: auto;
        margin-bottom: 20px;
      }
    }

    .comment {
      flex: 0 0 calc(50% - 145px);
      width: calc(50% - 145px);
      @include px-to-rem(18);
      line-height: 23px;
      @media(max-width: 1640px) {
        flex: 0 0 calc(50% - 135px);
        width: calc(50% - 135px);
      }
      @media(max-width: 1360px) {
        flex: 0 0 50%;
        width: 50%;
      }
      @include media-breakpoint-down(md) {
        width: auto;
        margin-bottom: 20px;
        padding-left: 0;
        padding-right: 0;
      }
    }

    .status {
      flex: 0 0 145px;
      width: 145px;
      @media(max-width: 1640px) {
        flex: 0 0 135px;
        width: 135px;
      }
      @media(max-width: 1360px) {
        flex: 0 0 100%;
        width: 100%;
        margin-top: 20px;
        padding-left: 0;
      }
      @include media-breakpoint-down(md) {
        width: auto;
        margin-bottom: 20px;
      }

      .status-name {
        @include px-to-rem(14);
        font-weight: 600;
        line-height: 20px;
        display: inline-flex;
        align-items: center;
        padding-left: 20px;
      }

      .purchased {
        background: url(../images/icons/purchased.svg) no-repeat left center;
        color: rgba(115, 164, 38, 1);
      }

      .in-processing {
        background: url(../images/icons/in-processing.svg) no-repeat left center;
        color: rgba(240, 184, 28, 1);
      }

      .rejected {
        background: url(../images/icons/rejected.svg) no-repeat left center;
        color: rgba(243, 132, 133, 1);
      }
    }

  }
}

.user-info {
  background: white;
  padding: 30px 35px;
  color: $palcheColor;
  @include media-breakpoint-down(md) {
    padding: 30px 15px;
  }

  .row {
    @include media-breakpoint-up(lg) {
      margin-left: -35px;
      margin-right: -35px;
      > div {
        padding-left: 35px;
        padding-right: 35px;
      }
    }
  }

  label {
    color: #1D2A4C;
    font-size: 16px;
    font-weight: 600;
    line-height: 32px;
    text-transform: uppercase;
    margin-bottom: 1px;
  }

  .save-btn {
    color: $palcheColor;
    @include px-to-rem(12);
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.24px;
    text-transform: uppercase;

    border-radius: 2px;
    padding: 4px 18px;
    background: $green;

    &:hover {
      transform: scale(1.05);
    }
  }
}

/* collective
-------------------------------------------------------*/
.collective-img {
  margin-bottom: 100px;
  @include media-breakpoint-down(md) {
    margin-bottom: 40px;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.collective-box {
  color: #F5F5F7;
  text-align: center;
  font-size: 60px;
  font-weight: 600;
  line-height: 75px;
  max-width: 1380px;
  margin: 0 auto 90px;
  @include media-breakpoint-down(lg) {
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 60px;
  }
  @include media-breakpoint-down(md) {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 0;
  }
}

.collective-img-box {
  position: relative;
  min-height: 400px;

  &:before {
    content: '';
    width: 282px;
    height: 43px;
    background: url(../images/icons/we-work-label.svg) no-repeat right center;
    position: absolute;
    top: 20px;
    right: 0;
    object-fit: cover;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .collective-image {
    object-fit: cover;
  }
}

.tm-units {
  background: #fff;
  color: #1d2a4c;
  padding-top: 70px;
  padding-bottom: 70px;
  @include media-breakpoint-down(md) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .box-title h2 {
    max-width: 100%;
  }

  .medium {
    color: #1D2A4C;
    @include px-to-rem(28);
    line-height: 42px;
    margin-bottom: 50px;
    @include media-breakpoint-down(md) {
      @include px-to-rem(20);
      line-height: 26px;
      margin-bottom: 30px;
    }
  }

  .small {
    color: #1D2A4C;
    @include px-to-rem(22);
    line-height: 36px;
    margin-bottom: 50px;
    @include media-breakpoint-down(md) {
      @include px-to-rem(18);
      line-height: 24px;
      margin-bottom: 30px;
    }
  }
}

/* spare-parts
-------------------------------------------*/
.spare-parts {
  padding-top: 70px;
  padding-bottom: 70px;

  .box-title h2 {
    max-width: 100%;
  }

  p {
    @include px-to-rem(22);
    line-height: 36px;
    margin-bottom: 50px;
    @include media-breakpoint-down(md) {
      @include px-to-rem(18);
      line-height: 26px;
    }
  }

  img {
    width: 100%;
    height: auto;
  }
}

/* our-certificates
-------------------------------------------*/
.our-certificates {
  padding-top: 70px;
  padding-bottom: 70px;

  @include media-breakpoint-down(md) {
    padding-top: 0;
  }

  .carousel-our-certificates {
    width: 100%;
    margin: 0 auto 50px;

    .item {
      text-align: center;

      img {
        object-fit: contain;
      }
    }
  }

  .wrap-arrows-button {
    margin-bottom: 120px;
    @include media-breakpoint-down(lg) {
      margin-bottom: 60px;
    }
    @include media-breakpoint-down(md) {
      margin-bottom: 0;
    }
  }
}

.catalog-swiper {
  .swiper {
    width: 100%;
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 500px;
  }
}

.product-images-swiper,
.product-videos-swiper {
  position: relative;
  padding-top: 22px;
  padding-bottom: 22px;
  width: 100px;

  .up-arrow {
    position: absolute;
    top: 0;
    display: flex;
    right: calc(50% - 9px);

    button {
      padding: 8px 9px 4px 9px;
      display: inline-flex;
      background: url(../images/icons/arrow-up-grey.svg) no-repeat center center;
      border: none;
      margin-bottom: 0;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .down-arrow {
    position: absolute;
    bottom: 0;
    display: flex;
    right: calc(50% - 9px);

    button {
      padding: 8px 9px 4px 9px;
      display: inline-flex;
      background: url(../images/icons/arrow-down-grey.svg) no-repeat center center;
      border: none;
      margin-bottom: 0;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

.product-videos-swiper {
  margin-bottom: 35px;
}

.product-videos-with-images-swiper {
  position: relative;
  padding-top: 22px;
  padding-bottom: 22px;
  width: 100px;

  @include media-breakpoint-down(sm) {
    padding: 0 22px;
    width: 100%;
    margin: 3em 0 1.5em 0;
  }

  .up-arrow {
    @include media-breakpoint-down(sm) {
      position: absolute;
      display: flex;
      left: 0;
      transform: rotate(-90deg);
      bottom: calc(50%);
    }

    button {
      padding: 8px 9px 4px 9px;
      display: inline-flex;
      background: url(../images/icons/arrow-up-grey.svg) no-repeat center center;
      border: none;
      margin-bottom: 0;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .down-arrow {
    @include media-breakpoint-down(sm) {
      position: absolute;
      display: flex;
      right: 0;
      transform: rotate(-90deg);
      bottom: calc(50%);
    }

    button {
      padding: 8px 9px 4px 9px;
      display: inline-flex;
      background: url(../images/icons/arrow-down-grey.svg) no-repeat center center;
      border: none;
      margin-bottom: 0;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

/* search-not-found */
.search-not-found {
  max-width: 80%;
  margin: 0 auto;
  text-align: center;
  font-size: 60px;
  position: relative;
  top: 50%;
  transform: translate(0, -50%);

  @include media-breakpoint-down(md) {
    font-size: 32px;
    max-width: 90%;
  }
}

.content-article-wrapper {
  background: rgba(29, 42, 76, 0.8);
  padding-top: 40px;
  padding-bottom: 20px;
}