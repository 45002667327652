@import "variables";
@import '~bootstrap/scss/mixins';

.scrolling-line {
  &__wrapper {
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    width: 100%;
    //font-size: 55px;
    //font-weight: 600;
    height: 80px;
    text-transform: uppercase;
    //color: rgba(170, 177, 206, 0.30);
    margin-bottom: 75px;
    @include media-breakpoint-down(lg) {
      margin-bottom: 50px;
    }
  }

  &__brand-viewport {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    width: 100%;
    height: 80px;
    backface-visibility: hidden;
  }

  &__brand-viewport-inner {
    position: absolute;
    width: 10000px;
    top: 0;
    height: 80px;
    display: table;
  }

  &__brand-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
    top: 50%;
    transform: translate(0, -50%);
    position: absolute;
    transition: 180s linear;
    text-transform: uppercase;
    animation: {
      name: animateFirstList;
      duration: 250s;
      timing-function: linear;
      iteration-count: infinite;
    };

    &.first-list {
      transform: translate(0, -50%);
    }

    &.second-list {
      transform: translate(100%, -50%);
      animation-name: animateSecondList;
    }

    span.brand-separator {
      &:not(img) {
        font-size: 55px;
        font-weight: 600;
        color: rgba(170, 177, 206, 0.30);

        display: flex;
        align-items: center;
        flex-shrink: 0;
      }

      img {
        width: auto;
        top: 3px;
        position: relative;
      }

      &:after {
        content: '/';
        display: inline-flex;
        color: $green;
        margin-left: 45px;
        margin-right: 45px;
        font-size: 1.2em;
      }
    }

    a {
      color: rgba(170, 177, 206, 0.30);
    }
  }
}

@keyframes animateFirstList {
  0% {
    transform: translate(0, -50%);
    opacity: 1
  }
  50% {
    transform: translate(-100%, -50%);
    opacity: 1
  }
  50.1% {
    transform: translate(-100%, -50%);
    opacity: 0
  }
  50.2% {
    transform: translate(100%, -50%);
    opacity: 0
  }
  50.3% {
    transform: translate(100%, -50%);
    opacity: 1
  }
  to {
    transform: translate(0, -50%);
    opacity: 1
  }
}

@keyframes animateSecondList {
  0% {
    transform: translate(100%, -50%);
    opacity: 1
  }
  50% {
    transform: translate(0, -50%);
    opacity: 1
  }
  99.7% {
    transform: translate(-100%, -50%);
    opacity: 1
  }
  99.8% {
    transform: translate(-100%, -50%);
    opacity: 0
  }
  99.9% {
    transform: translate(100%, -50%);
    opacity: 0
  }
  to {
    transform: translate(100%, -50%);
    opacity: 1
  }
}